program drawNetwork;

uses
  WEBLib.Forms,
  ufMain in 'ufMain.pas'{*.html},
  uNetwork in 'uNetwork.pas',
  uGraphUtils in 'uGraphUtils.pas',
  uDrawTypes in 'uDrawTypes.pas',
  uNetworkCanvas in 'uNetworkCanvas.pas',
  uController in 'uController.pas',
  uDrawReaction in 'uDrawReaction.pas',
  uNetworkTypes in 'uNetworkTypes.pas',
  uLayout in 'uLayout.pas',
  uCreateNetworks in 'uCreateNetworks.pas',
  uMath in 'uMath.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
end.
