{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2016 - 2018                                 }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCHTMLText;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCBitmapContainer, 
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes, WEBLib.Controls
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,UITypes, Types
  {$ENDIF}
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 1; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 1; // Build nr.

  // version history
  // v1.0.0.0 : First release
  // v1.1.0.0 : New : Support for float left & float right images
  //          : New : Support for float left text
  //          : New : Support for loading images from file
  //          : New : Stroke property exposed
  // v1.1.0.1 : Fixed : Issue with autosize rounding


type
  TTMSFNCHTMLTextAnchorClickEvent = procedure(Sender: TObject; AAnchor: string) of object;

  TTMSFNCHTMLText = class;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCHTMLText = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    FDisableAdjustSize: Boolean;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FOnAnchorClick: TTMSFNCHTMLTextAnchorClickEvent;
    FURLColor: TTMSFNCGraphicsColor;
    FShadowOffset: Integer;
    FShadowColor: TTMSFNCGraphicsColor;
    FDisplayHTML: Boolean;
    FURLUnderline: Boolean;
    FText: String;
    FFont: TTMSFNCGraphicsFont;
    FWordWrapping: Boolean;
    FAutoSize: Boolean;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FAutoOpenURL: Boolean;
    FAutoSizeSpacing: Single;
    FAutoHeight: Boolean;
    FAutoWidth: Boolean;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    procedure SetShadowOffset(const Value: integer);
    procedure SetURLColor(const Value: TTMSFNCGraphicsColor);
    procedure SetShadowColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetText(const Value: String);
    procedure SetDisplayHTML(const Value: Boolean);
    procedure SetURLUnderline(const Value: Boolean);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetAS(const Value: Boolean);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    function IsAutoSizeSpacingStored: Boolean;
    procedure SetAutoSizeSpacing(const Value: Single);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetAutoHeight(const Value: Boolean);
    procedure SetAutoWidth(const Value: Boolean);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
  protected
    function GetVersion: string; override;
    function CanDrawDesignTime: Boolean; virtual;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure RegisterRuntimeClasses; override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure ApplyAutoSize; virtual;
    procedure FillChanged(Sender: TObject);
    procedure DoFontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure DoAnchorClick(Anchor: string);
    procedure DrawHTMLText(AGraphics: TTMSFNCGraphics); virtual;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function GetTextRect: TRectF; virtual;
    procedure Assign(Source: TPersistent); override;
    procedure DoMouseMove({%H-}Shift: TShiftState; X, Y: Single);
    procedure DoMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; X, Y: Single);
    procedure UpdateControlAfterResize; override;
    function IsHTML: boolean;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
  published
    property AutoSize: Boolean read FAutoSize write SetAS {$IFNDEF WEBLIB}default False{$ENDIF};
    property AutoHeight: Boolean read FAutoHeight write SetAutoHeight {$IFNDEF WEBLIB}default True{$ENDIF};
    property AutoWidth: Boolean read FAutoWidth write SetAutoWidth {$IFNDEF WEBLIB}default True{$ENDIF};
    property AutoSizeSpacing: Single read FAutoSizeSpacing write SetAutoSizeSpacing {$IFNDEF WEBLIB}stored IsAutoSizeSpacingStored nodefault{$ENDIF};
    property AutoOpenURL: Boolean read FAutoOpenURL write FAutoOpenURL {$IFNDEF WEBLIB}default True{$ENDIF};
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property DisplayHTML: Boolean read FDisplayHTML write SetDisplayHTML {$IFNDEF WEBLIB}default True{$ENDIF};
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign {$IFNDEF WEBLIB}default gtaCenter{$ENDIF};
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming {$IFNDEF WEBLIB}default gttNone{$ENDIF};
    property ShadowColor: TTMSFNCGraphicsColor read FShadowColor write SetShadowColor {$IFNDEF WEBLIB}default gcGray{$ENDIF};
    property ShadowOffset: integer read FShadowOffset write SetShadowOffset {$IFNDEF WEBLIB}default 1{$ENDIF};
    property Fill;
    property Stroke;
    property Text: String read FText write SetText;
    property URLColor: TTMSFNCGraphicsColor read FURLColor write SetURLColor {$IFNDEF WEBLIB}default gcBlue{$ENDIF};
    property URLUnderline: Boolean read FURLUnderline write SetURLUnderline {$IFNDEF WEBLIB}default True{$ENDIF};
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign {$IFNDEF WEBLIB}default gtaCenter{$ENDIF};
    property Version: String read GetVersion;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping {$IFNDEF WEBLIB}default False{$ENDIF};
    property OnAnchorClick: TTMSFNCHTMLTextAnchorClickEvent read FOnAnchorClick write FOnAnchorClick;
  end;

implementation

uses
  SysUtils, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles, WEBLib.Graphics;

{ TTMSFNCHTMLText }

procedure TTMSFNCHTMLText.ApplyAutoSize;
var
  R: TRectF;
begin
  if not FDisableAdjustSize and not (csLoading in ComponentState) then
  begin
    FDisableAdjustSize := True;
    try
      if FAutoSize and (Text <> '') then
      begin
        R := GetTextRect;
        {$IFDEF FMXLIB}
        if AutoHeight and AutoWidth then
          SetBounds(Position.X, Position.Y, R.Width + R.Left * 2 + Font.Size / 3, R.Height + R.Top * 2)
        else if AutoWidth then
          SetBounds(Position.X, Position.Y, R.Width + R.Left * 2 + Font.Size / 3, Height)
        else if AutoHeight then
          SetBounds(Position.X, Position.Y, Width, Round(R.Height) + R.Top * 2);
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        if AutoHeight and AutoWidth then
          SetBounds(Left, Top, Round((R.Right - R.Left) + R.Left * 2 + Font.Size / 3), Round((R.Bottom - R.Top) + R.Top * 2))
        else if AutoWidth then
          SetBounds(Left, Top, Round((R.Right - R.Left) + R.Left * 2 + Font.Size / 3), Height)
        else if AutoHeight then
          SetBounds(Left, Top, Width, Round((R.Bottom - R.Top) + R.Top * 2));
        {$ENDIF}
      end;
    finally
      FDisableAdjustSize := False;
    end;
  end;
end;

function TTMSFNCHTMLText.CanDrawDesignTime: Boolean;
begin
  Result := True;
end;

procedure TTMSFNCHTMLText.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  Font.Height := TTMSFNCUtils.MulDivInt(Font.Height, M, D);
end;

constructor TTMSFNCHTMLText.Create(AOwner: TComponent);
begin
  inherited;
  FURLColor := gcBlue;
  FTrimming := gttNone;
  FURLUnderline := True;
  FDisplayHTML := True;
  FShadowOffset := 1;
  FAutoOpenURL := True;
  FShadowColor := gcGray;
  FWordWrapping := False;
  FAutoSize := False;
  FAutoHeight := True;
  FAutoWidth := True;
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @DoFontChanged;
  FAutoSizeSpacing := 0;
  Stroke.Color := gcNull;

  if IsDesignTime then
  begin
    Text := 'TTMSFNCHTMLText';
    DisableBackground;
  end;

  Width := 100;
  Height := 50;
end;

destructor TTMSFNCHTMLText.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCHTMLText.DoAnchorClick(Anchor: string);
begin
  if AutoOpenURL then
    TTMSFNCUtils.OpenURL(Anchor);

  if Assigned(OnAnchorClick) then
    OnAnchorClick(Self, Anchor);
end;

procedure TTMSFNCHTMLText.DoMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState;
  X, Y: Single);
var
  Anchor: String;
  g: TTMSFNCGraphics;
  r: TRectF;
begin
  if IsHTML then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.BitmapContainer := BitmapContainer;
    g.Font.AssignSource(Font);
    try
      if AutoSize then
      begin
        if AutoWidth and AutoHeight then
          r := RectF(AutoSizeSpacing, AutoSizeSpacing, Width, Height)
        else if AutoWidth then
          r := RectF(AutoSizeSpacing, 0, Width, Height)
        else if AutoHeight then
          r := RectF(0, AutoSizeSpacing, Width, Height);
      end
      else
        r := LocalRect;

      Anchor := g.DrawText(r, Text, WordWrapping, HorizontalTextAlign, VerticalTextAlign, Trimming, 0, -1, -1, True, True, X, Y);
      if Anchor <> '' then
        DoAnchorClick(Anchor);
    finally
      g.free;
    end;
  end;
end;

procedure TTMSFNCHTMLText.DoMouseMove(Shift: TShiftState; X, Y: Single);
var
  Anchor: string;
  g: TTMSFNCGraphics;
  r: TRectF;
begin
  inherited;
  if IsHTML then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.BitmapContainer := BitmapContainer;
    g.Font.AssignSource(Font);
    try
      if AutoSize then
      begin
        if AutoWidth and AutoHeight then
          r := RectF(AutoSizeSpacing, AutoSizeSpacing, Width, Height)
        else if AutoWidth then
          r := RectF(AutoSizeSpacing, 0, Width, Height)
        else if AutoHeight then
          r := RectF(0, AutoSizeSpacing, Width, Height);
      end
      else
        r := LocalRect;

      Anchor := g.DrawText(r, Text, WordWrapping, HorizontalTextAlign, VerticalTextAlign, Trimming, 0, -1, -1, True, True, X, Y);
      if (Anchor <> '') then
        Cursor := crHandPoint
      else
        Cursor := crDefault;
    finally
      g.Free;
    end;
  end;
end;

procedure TTMSFNCHTMLText.DrawHTMLText(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
begin
  AGraphics.Font.AssignSource(Font);

  if not Enabled then
    AGraphics.Font.Color := gcMedGray;

  AGraphics.URLColor := URLColor;
  AGraphics.URLUnderline := URLUnderline;
  if AutoSize then
  begin
    if AutoWidth and AutoHeight then
      r := RectF(AutoSizeSpacing, AutoSizeSpacing, Width, Height)
    else if AutoWidth then
      r := RectF(AutoSizeSpacing, 0, Width, Height)
    else if AutoHeight then
      r := RectF(0, AutoSizeSpacing, Width, Height);
  end
  else
    r := LocalRect;
  AGraphics.DrawText(r, Text, WordWrapping, HorizontalTextAlign, VerticalTextAlign, Trimming, 0, -1, -1, DisplayHTML);
end;

procedure TTMSFNCHTMLText.FillChanged(Sender: TObject);
begin
  Invalidate;
end;

procedure TTMSFNCHTMLText.DoFontChanged(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCHTMLText.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCHTMLText.GetTextRect: TRectF;
var
  g: TTMSFNCGraphics;
  R: TRectF;
  w, h: Single;
begin
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
  g.BitmapContainer := BitmapContainer;
  g.Font.AssignSource(Font);
  try
    g.Font.AssignSource(Font);
    if WordWrapping then
      R := RectF(0, 0, Width, 10000)
    else
      R := RectF(0, 0, 10000, 10000);

    Result := g.CalculateText(Text, R, WordWrapping, DisplayHTML);
    w := Result.Right - Result.Left;
    h := Result.Bottom - Result.Top;
    Result.Right := Result.Left + w + AutoSizeSpacing * 2;
    Result.Bottom := Result.Top + h + AutoSizeSpacing * 2;
  finally
    g.Free;
  end;
end;

function TTMSFNCHTMLText.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCHTMLText.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  DoMouseDown(Button, Shift, X, Y);
end;

procedure TTMSFNCHTMLText.HandleMouseMove(Shift: TShiftState; X, Y: Single);
begin
  inherited;
  DoMouseMove(Shift, X, Y);
end;

function TTMSFNCHTMLText.IsAutoSizeSpacingStored: Boolean;
begin
  Result := AutoSizeSpacing <> 0;
end;

function TTMSFNCHTMLText.IsHTML: boolean;
begin
  if not DisplayHTML then
    Result := False
  else
    Result := (AnsiPos('</', Text) > 0) or (AnsiPos('/>', Text)  > 0) or (AnsiPos('<BR>', UpperCase(Text)) > 0);
end;

procedure TTMSFNCHTMLText.SetAS(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetAutoHeight(const Value: Boolean);
begin
  if FAutoHeight <> Value then
  begin
    FAutoHeight := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetAutoSizeSpacing(const Value: Single);
begin
  if FAutoSizeSpacing <> Value then
  begin
    FAutoSizeSpacing := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetAutoWidth(const Value: Boolean);
begin
  if FAutoWidth <> Value then
  begin
    FAutoWidth := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  ApplyAutoSize;
end;

procedure TTMSFNCHTMLText.SetDisplayHTML(const Value: Boolean);
begin
  if FDisplayHTML <> Value then
  begin
    FDisplayHTML := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCHTMLText.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetShadowColor(const Value: TTMSFNCGraphicsColor);
begin
  if (FShadowColor <> Value) then
  begin
    FShadowColor := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetShadowOffset(const Value: integer);
begin
  if (FShadowOffset <> Value) then
  begin
    FShadowOffset := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetURLColor(const Value: TTMSFNCGraphicsColor);
begin
  if (FURLColor <> Value) then
  begin
    FURLColor := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetURLUnderline(const Value: Boolean);
begin
  if FURLUnderline <> Value then
  begin
    FURLUnderline := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    ApplyAutoSize;
    Invalidate;
  end;
end;

procedure TTMSFNCHTMLText.StrokeChanged(Sender: TObject);
begin
  Invalidate;
end;

procedure TTMSFNCHTMLText.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
    Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundStrokeColor(c) then
    Stroke.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
    Font.Color := c;
end;

procedure TTMSFNCHTMLText.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCHTMLText then
  begin
    FTrimming := (Source as TTMSFNCHTMLText).Trimming;
    FAutoSize := (Source as TTMSFNCHTMLText).AutoSize;
    FAutoWidth := (Source as TTMSFNCHTMLText).AutoWidth;
    FAutoHeight := (Source as TTMSFNCHTMLText).AutoHeight;
    FAutoSizeSpacing := (Source as TTMSFNCHTMLText).AutoSizeSpacing;
    FAutoOpenURL := (Source as TTMSFNCHTMLText).AutoOpenURL;
    FDisplayHTML := (Source as TTMSFNCHTMLText).DisplayHTML;
    FFont.AssignSource((Source as TTMSFNCHTMLText).Font);
    FHorizontalTextAlign := (Source as TTMSFNCHTMLText).HorizontalTextAlign;
    FShadowColor := (Source as TTMSFNCHTMLText).ShadowColor;
    FShadowOffset := (Source as TTMSFNCHTMLText).ShadowOffset;
    FText := (Source as TTMSFNCHTMLText).Text;
    FURLColor := (Source as TTMSFNCHTMLText).URLColor;
    FURLUnderline := (Source as TTMSFNCHTMLText).URLUnderline;
    FVerticalTextAlign := (Source as TTMSFNCHTMLText).VerticalTextAlign;
    FWordWrapping := (Source as TTMSFNCHTMLText).WordWrapping;
  end;
end;

procedure TTMSFNCHTMLText.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCHTMLText);
end;

procedure TTMSFNCHTMLText.ResetToDefaultStyle;
begin
  inherited;
  Fill.Color := gcWhite;
  Stroke.Color := gcSilver;
  Font.Color := gcBlack;
end;

procedure TTMSFNCHTMLText.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCHTMLText.UpdateControlAfterResize;
begin
  inherited;
  ApplyAutoSize;
end;

procedure TTMSFNCHTMLText.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;
  AGraphics.BitmapContainer := BitmapContainer;
  DrawHTMLText(AGraphics);
  if IsDesignTime and CanDrawDesignTime then
    AGraphics.DrawFocusRectangle(ARect, gcBlack);
end;

end.
