{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2018 - 2019                                 }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}
unit jqwidget;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses js, web, libjquery, sysutils;

type

  { TJQXEvent }

  TJQXEvent = class external name 'jqxEvent' (TJSUIevent)
  private
    FArgs: TJSObject; external name 'args';
  Public
    property Args: TJSObject read FArgs;
  end;

  TJQXEventHandler = reference to function(Event: TJQXEvent): boolean;

  { TJQXElementEvent }

  TJQXElementEvent = class external name 'jqxElementEvent' (TJSUIevent)
  private
    FArgs: TJSHTMLElement; external name 'args';
  Public
    property Args: TJSHTMLElement read FArgs;
  end;

  TJQXElementEventHandler = reference to function(Event: TJQXElementEvent): boolean;

  TJQXWidgetCalendar = class external name 'jqxCalendar' (TJQuery)
  public
    function jqxCalendar(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxCalendar(Const aParameter : String) : TJSObject;overload;
    function jqxCalendar(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxCalendar(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxCalendar(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxCalendar(Const aParameter : String; aValue : TJSDate) : TJQuery;overload;
    function jqxCalendar(Const aParameter : String; aValue : TJSDate; aValue2 : TJSDate) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetDateTimeInput = class external name 'jqxDateTimeInput' (TJQuery)
  public
    function jqxDateTimeInput(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxDateTimeInput(Const aParameter : String) : TJSObject;overload;
    function jqxDateTimeInput(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxDateTimeInput(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxDateTimeInput(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxDateTimeInput(Const aParameter : String; aValue : TJSDate) : TJQuery;overload;
    function jqxDateTimeInput(Const aParameter : String; aValue : TJSDate; aValue2 : TJSDate) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetMenu = class external name 'jqxMenu' (TJQuery)
  public
    function jqxMenu(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxMenu(Const aParameter : String) : TJQuery;overload;
    function jqxMenu(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxMenu(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxMenu(Const aParameter : String; aName : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetMaskedInput = class external name 'jqxMaskedInput' (TJQuery)
  public
    function jqxMaskedInput(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxMaskedInput(Const aParameter : String) : string;overload;
    function jqxMaskedInput(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxMaskedInput(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxMaskedInput(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetColorPicker = class external name 'jqxColorPicker' (TJQuery)
  public
    function jqxColorPicker(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxColorPicker(Const aParameter : String) : TJSObject;overload;
    function jqxColorPicker(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxColorPicker(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxColorPicker(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetComboBox = class external name 'jqxComboBox' (TJQuery)
  public
    function jqxComboBox(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxComboBox(Const aParameter : String) : TJSObject;overload;
    function jqxComboBox(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxComboBox(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxComboBox(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetDropDownList = class external name 'jqxDropDownList' (TJQuery)
  public
    function jqxDropDownList(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxDropDownList(Const aParameter : String) : TJSObject;overload;
    function jqxDropDownList(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxDropDownList(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxDropDownList(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQDataAdapter = class external name '$.jqx.dataAdapter'
    constructor new(aConfig : TJSObject);
    constructor new(aConfig : String);
  end;

  TJQXWidgetGrid = class external name 'jqxGrid' (TJQuery)
  public
    function jqxGrid(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxGrid(Const aParameter : String) : TJSObject;overload;
    function jqxGrid(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxGrid(Const aParameter : String; aValue : Integer) : string;overload;
    function jqxGrid(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxGrid(Const aParameter : String; aValue : TJQDataAdapter) : TJQuery;overload;
    function jqxGrid(Const aParameter : String; aID : String; aValue : TJSObject) : TJSObject;overload;
    function jqxGrid(Const aParameter : String; aIndex : integer; aID: String; aValue : String) : TJQuery;overload;
    function jqxGrid(Const aParameter : String; aRow : integer; aCol: integer; aValue : String) : TJQuery;overload;
    function jqxGrid(Const aParameter : String; aRow : integer; aCol: integer) : string;overload;
    function jqxGrid(Const aParameter : String; aCol : integer; aValue: string) : string;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetNumberInput = class external name 'jqxNumberInput' (TJQuery)
  public
    function jqxNumberInput(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxNumberInput(Const aParameter : String) : TJSObject;overload;
    function jqxNumberInput(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxNumberInput(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxNumberInput(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxNumberInput(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetRating = class external name 'jqxRating' (TJQuery)
  public
    function jqxRating(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxRating(Const aParameter : String) : TJSObject;overload;
    function jqxRating(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxRating(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxRating(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxRating(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetButton = class external name 'jqxButton' (TJQuery)
  public
    function jqxButton(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxButton(Const aParameter : String) : TJSObject;overload;
    function jqxButton(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxButton(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxButton(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetButtonGroup = class external name 'jqxButtonGroup' (TJQuery)
  public
    function jqxButtonGroup(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxButtonGroup(Const aParameter : String) : TJSObject;overload;
    function jqxButtonGroup(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxButtonGroup(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxButtonGroup(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxButtonGroup(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetKnob = class external name 'jqxKnob' (TJQuery)
  public
    function jqxKnob(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxKnob(Const aParameter : String) : TJSObject;overload;
    function jqxKnob(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxKnob(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function jqxKnob(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetProgressBar = class external name 'jqxProgressBar' (TJQuery)
  public
    function jqxProgressBar(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxProgressBar(Const aParameter : String) : TJSObject;overload;
    function jqxProgressBar(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxProgressBar(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function jqxProgressBar(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetSlider = class external name 'jqxSlider' (TJQuery)
  public
    function jqxSlider(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxSlider(Const aParameter : String) : TJSObject;overload;
    function jqxSlider(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxSlider(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function jqxSlider(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxSlider(Const aParameter : String; aValue : TJSArray) : TJQuery;overload;
    function jqxSlider(Const aParameter : String; aMinimumValue : Double; aMaximumValue : Double) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetRangeSelector = class external name 'jqxRangeSelector' (TJQuery)
  public
    function jqxRangeSelector(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxRangeSelector(Const aParameter : String) : TJSObject;overload;
    function jqxRangeSelector(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxRangeSelector(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function jqxRangeSelector(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxRangeSelector(Const aParameter : String; aValue : TJSArray) : TJQuery;overload;
    function jqxRangeSelector(Const aParameter : String; aMinimumValue : Double; aMaximumValue : Double) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetTagCloud = class external name 'jqxTagCloud' (TJQuery)
  public
    function jqxTagCloud(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxTagCloud(Const aParameter : String) : TJSObject;overload;
    function jqxTagCloud(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxTagCloud(Const aParameter : String; aValue : Double) : TJQuery;overload;
    function jqxTagCloud(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxTagCloud(Const aParameter : String; aValue : TJSArray) : TJQuery;overload;
    function jqxTagCloud(Const aParameter : String; aValue : TJQDataAdapter) : TJQuery;overload;
    function jqxTagCloud(Const aParameter : String; aValue : TJQDataAdapter; Const aParameter2 : String; aValue2 : String) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

  TJQXWidgetResponsivePanel = class external name 'jqxResponsivePanel' (TJQuery)
  public
    function jqxResponsivePanel(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxResponsivePanel(Const aParameter : String) : TJSObject;overload;
    function jqxResponsivePanel(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxResponsivePanel(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxResponsivePanel(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxResponsivePanel(Const aParameter : String; aValue : TJQuery) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXElementEventHandler) : TJQuery;
  end;

  TJQXWidgetTabs = class external name 'jqxTabs' (TJQuery)
  public
    function jqxTabs(Const aParameter : TJSObject) : TJQuery;overload;
    function jqxTabs(Const aParameter : String) : TJSObject;overload;
    function jqxTabs(Const aParameter : String; aValue : String) : TJQuery;overload;
    function jqxTabs(Const aParameter : String; aValue : Integer) : TJQuery;overload;
    function jqxTabs(Const aParameter : String; aValue : Boolean) : TJQuery;overload;
    function jqxTabs(Const aParameter : String; aValue : TJQuery) : TJQuery;overload;
    function on(Const aParameter : String; aValue : TJQXEventHandler) : TJQuery;
  end;

implementation

end.
