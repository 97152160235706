unit uController;

interface

uses SysUtils, Classes, UITypes, uNetwork, contnrs, WEBLib.ExtCtrls, WEBLib.Utils,
     WEBLib.Buttons, WEBLib.Graphics, WEBLib.Controls, WEBLib.StdCtrls,
     WEBLib.Dialogs;

const
  NOT_SELECTED = -1;

type
  TStackOfSavedStates = array of TNetworkSavedState;

  TMouseStatus = (sIdle, sAddNode, sAddUniUni, sAddUniBi, sAddBiUni, sAddBiBi, sMouseDown, sMoveCentroid);

  TNetworkStack = class
     networkStack : TStackOfSavedStates;
     stackCounter : integer;

     procedure push (n : TNetworkSavedState);
     function  pop : TNetworkSavedState;
     function  ifEmpty : boolean;constructor Create;
  end;


  TController = class
     mStatus : TMouseStatus;
     srcNode, destNode : integer;
     selectedNode : integer;
     currentX, currentY : double;

     anyByAny_nReactants : integer;
     anyByAny_nProducts : integer;

     sourceNodeCounter, destNodeCounter : integer;

     sourceNodes : array of TNode;
     destNodes : array of TNode;

     network : TNetwork;
     undoStack : TNetworkStack;

     procedure loadModel (modelStr : string);
     procedure setAddNodeStatus;
     procedure setAddUniUniReaction;
     procedure setAddUniBiReaction;
     procedure setAddBiUniReaction;
     procedure setAddBiBiReaction;

     procedure setIdleStatus;
     function  addNode (Id : string; x, y : double) : TNode; overload;
     procedure addNode (x, y : double); overload;
     function  addReaction (Id : string; src, dest : TNode) : integer;
     procedure prepareUndo;
     procedure undo;
     procedure deleteSelectedItems;

     procedure addUniUniReactionMouseDown (Sender : TObject; x, y : double);
     procedure addAnyReactionMouseDown (Sender : TObject; x, y : double; nReactants, nProducts : integer);

     procedure OnMouseDown (Sender : TObject; Button: TMouseButton; Shift: TShiftState; X, Y: double);
     procedure OnMouseMove(Sender: TObject; Shift: TShiftState; X, Y: double);
     procedure OnMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: double);

     constructor Create (network : TNetwork);
  end;


implementation

Uses JS, Web, WEBLib.JSON;

constructor TNetworkStack.Create;
begin
  stackCounter := -1;
end;


procedure TNetworkStack.push (n : TNetworkSavedState);
begin
  inc (stackCounter);
  setLength(networkStack, Length(networkStack) + 1);
  networkStack[stackCounter] := n;
end;


function TNetworkStack.pop : TNetworkSavedState;
begin
  if stackCounter <> -1 then
     begin
     result := networkStack[stackCounter];
     dec (stackCounter);
     end;
end;


function TNetworkStack.IfEmpty : boolean;
begin
   result := True;
   if stackCounter <> -1 then
      result := False;
end;


constructor TController.Create (network : TNetwork);
begin
  undoStack := TNetworkStack.Create;

  self.network := network;
  mStatus := sIdle;
  srcNode := NOT_SELECTED; destNode := NOT_SELECTED;
  sourceNodeCounter := -1; destNodeCounter := -1;
end;


procedure TController.prepareUndo;
begin
  undostack.push (network.getCurrentState);
end;


procedure TController.undo;
begin
  if not undoStack.ifEmpty then
     network.loadState (undoStack.pop);
end;


procedure TController.deleteSelectedItems;
var i, j : integer;
    alength : integer;
begin
  for i := 0 to length (network.nodes) - 1 do
      begin
      if network.nodes[i].selected then
         begin
         if not network.hasReactions (network.nodes[i]) then
            begin
            alength := Length(network.nodes);
            for j := i + 1 to alength - 1 do
                network.nodes[j - 1] := network.nodes[j];
            setLength (network.nodes, alength - 1);
            exit;
            end
         else
            showmessage ('Delete connecting reactions first');
         end;
      end;

  for i := 0 to length (network.reactions) - 1 do
      begin
      if network.reactions[i].selected then
         begin
         alength := Length(network.reactions);
         for j := i + 1 to alength - 1 do
             network.reactions[j - 1] := network.reactions[j];
         setLength (network.reactions, alength - 1);
         exit;
         end;
      end;

end;


procedure TController.loadModel (modelStr : string);
begin
  network.loadModel (modelStr);
end;


procedure TController.setIdleStatus;
begin
  mStatus := sIdle;
end;


procedure TController.setAddNodeStatus;
begin
   mStatus := sAddNode;
end;


procedure TController.setAddUniUniReaction;
begin
  mStatus := sAddUniUni;
  srcNode := NOT_SELECTED;
  destNode := NOT_SELECTED;
end;


procedure TController.setAddUniBiReaction;
begin
  mStatus := sAddUniBi;
  srcNode := NOT_SELECTED;
  destNode := NOT_SELECTED;
end;


procedure TController.setAddBiUniReaction;
begin
  mStatus := sAddBiUni;
  srcNode := NOT_SELECTED;
  destNode := NOT_SELECTED;
end;


procedure TController.setAddBiBiReaction;
begin
  mStatus := sAddBiBi;
  srcNode := NOT_SELECTED;
  destNode := NOT_SELECTED;
end;


function TController.addReaction (Id : string; src, dest : TNode) : integer;
begin
  prepareUndo;
  result := network.addUniUniReaction (Id, src, dest);
end;



function TController.addNode (Id : string; x, y : double) : TNode;
begin
  prepareUndo;
  result := network.addNode (Id, x, y);
end;


procedure TController.addNode (x, y : double);
begin
  prepareUndo;
  network.addNode ('node' + inttostr (length (network.nodes) + 1), x, y);
end;


procedure TController.addUniUniReactionMouseDown (Sender : TObject; x, y : double);
var index: integer;
begin
  (sender as TPaintBox).cursor := crHandPoint;

  if srcNode = NOT_SELECTED then  // ie srcnode not chosen yet
     begin
     if network.overNode (x, y, index) <> nil then
        begin
        srcNode := index;
        destNode := NOT_SELECTED;
        network.nodes[index].addReactionSelected := True;
        end
     else
        mStatus := sIdle;
     end
  else
  if srcNode <> NOT_SELECTED then
     begin
    if network.overNode (x, y, index) <> nil then
       begin
       destNode := index;
       prepareUndo;
       network.addUniUniReaction ('J' + inttostr (length(network.reactions)), network.nodes[srcNode], network.nodes[destNode]);
       network.nodes[srcNode].addReactionSelected := False;
       srcNode := NOT_SELECTED; destNode := NOT_SELECTED;
      (sender as TPaintBox).cursor := crDefault;
      end
   else
      begin
      mStatus := sIdle;
      srcNode := NOT_SELECTED;
      destNode := NOT_SELECTED;
      end;
   end;
end;


procedure TController.addAnyReactionMouseDown (Sender : TObject; x, y : double; nReactants, nProducts : integer);
var index: integer;
begin
  (sender as TPaintBox).cursor := crHandPoint;

  anyByAny_nReactants := nReactants;
  anyByAny_nProducts := nProducts;

  if sourceNodeCounter = -1 then
     setLength (sourceNodes, nReactants);
  if destNodeCounter = -1 then
     setLength (destNodes, nProducts);

  if sourceNodeCounter < nReactants - 1 then
     begin
     if network.overNode (x, y, sourceNodes[sourceNodeCounter+1]) then
        begin
        sourceNodeCounter := sourceNodeCounter + 1;
        // collect source node id
        sourceNodes[sourceNodeCounter].Selected := True;
        end;
     end
  else
     begin
     if destNodeCounter < nProducts - 1 then
        begin
        // collect source node id
        if network.overNode (x, y, destNodes[destNodeCounter+1]) then
           begin
           destNodeCounter := destNodeCounter + 1;
           destNodes[destNodeCounter].Selected := True;
           end;
        if not (destNodeCounter < nProducts - 1) then
           begin
           prepareUndo;
           network.AddAnyToAnyEdge (sourceNodes, destNodes, index);
           sourceNodeCounter := -1;
           destNodeCounter := -1;
           network.UnSelectAll;
           //if Assigned (FModelChangeEvent) then
           //   FModelChangeEvent (self, mcAddReaction, edgeIndex, edge.name);
          end;
        end;
     end;
end;


procedure TController.OnMouseDown (Sender : TObject; Button: TMouseButton; Shift: TShiftState; X, Y: double);
var index: integer;
begin
  try
    case mStatus of
       sAddNode :
         begin
         addNode (x, y);
         exit;
         end;

       sAddUniUni : begin addUniUniReactionMouseDown (Sender, x, y); exit; end;
       sAddUniBi  : begin addAnyReactionMouseDown (Sender, x, y, 1, 2); exit; end;
       sAddBiUni  : begin addAnyReactionMouseDown (Sender, x, y, 2, 1); exit; end;
       sAddBiBi   : begin addAnyReactionMouseDown (Sender, x, y, 2, 2); exit; end;

       sIdle : begin end;
    end;


  if network.overNode (x, y, index) <> nil then
     begin
     mStatus := sMouseDown;
     network.unSelectAll;
     selectedNode := index;
     network.nodes[index].selected := True;
     currentX := X; currentY := Y;
     exit;
     end;

  if network.overEdge (x, y, index) <> nil then
     begin
     network.unSelectAll;
     network.reactions[index].selected := true;
     exit;
     end;

  if network.overCentroid (x, y, Index) <> nil then
     begin
     network.unSelectAll;
     currentX := X; currentY := Y;
     mStatus := sMoveCentroid;
     exit;
     end;

  mStatus := sIdle;
  network.unSelectAll;
  finally
    //(sender as TWebPaintbox).invalidate;
  end;
end;


procedure TController.OnMouseMove(Sender: TObject; Shift: TShiftState; X, Y: double);
var dx, dy : double;
    index : integer;
begin
  case mStatus of

     sMouseDown : begin
                  dx := (x - currentX); dy := (y - currentY);
                  network.nodes[selectedNode].state.x := network.nodes[selectedNode].state.x + dx;
                  network.nodes[selectedNode].state.y := network.nodes[selectedNode].state.y + dy;
                  currentX := x; currentY := y;
                  exit;
                  end;

     sMoveCentroid : begin
                     // Do we implement this, not sure?
                     exit;
                     end;
    end;

  // Put halo around node is we're added reactions.
  if (mStatus in [sAddUniUni, sAddUniBi, sAddBiUni, sAddBiBi]) and (network.overNode (x, y, index) <> nil) then
     begin
     network.nodes[index].addReactionSelected := True;
     end
  else
    network.unReactionSelect;
end;


procedure TController.OnMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: double);
begin
  if mStatus = sMouseDown then
     mStatus := sIdle;
end;



end.
