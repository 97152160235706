{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2016		                                     }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCButton;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.StdCtrls, WEBLib.TMSFNCTypes, WEBLib.TMSFNCImage, WEBLib.Controls,
  WEBLib.TMSFNCHTMLText, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCGraphicsTypes
  {$IFDEF FMXLIB}
  ,FMX.Types, UIConsts
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,UITypes, Types
  {$ENDIF}
  {$ENDIF}
  {$IFDEF VCLLIB}
  ,Windows, Messages
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,ExtCtrls
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 5; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.0.1 : Fixed: Issue with button caption not drawn in LCL
  // v1.0.0.2 : Fixed: Issue with hiding caption in VCL
  // v1.0.0.3 : Fixed: Issue with restoring image alignment
  // v1.0.0.4 : Fixed: Issue with font color in disabled state
  // v1.0.0.5 : Fixed: Issue with setting Text property at designtime in TMS WEB Core

type
  {$IFDEF FMXLIB}
  TTMSFNCButtonAlignLayout = TAlignLayout;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCButtonAlignLayout = TAlign;
  {$ENDIF}

  { TTMSFNCButton }

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFDEF WEBLIB}
  TTMSFNCButton = class(TWebButton, ITMSFNCBitmapContainer)
  {$ELSE}
  TTMSFNCButton = class(TCustomButton, ITMSFNCBitmapContainer)
  {$ENDIF}
  private
    FOldFontColor: TTMSFNCGraphicsColor;
    {$IFDEF CMNWEBLIB}
    FOldImageWidth: Integer;
    {$ENDIF}
    {$IFNDEF CMNWEBLIB}
    FOldImageWidth: Single;
    {$ENDIF}
    FImage: TTMSFNCImage;
    FText: TTMSFNCHTMLText;
    FShowImage: Boolean;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FBitmap: TTMSFNCBitmap;
    FBitmapName: String;
    FBitmapSize: Integer;
    FShowText: Boolean;
    {$IFDEF VCLLIB}
    FBlockTextChange: Boolean;
    {$ENDIF}
    {$IFDEF LCLLIB}
    FButtonImage: TTMSFNCBitmap;
    FInvalidateTimer: TTimer;
    {$ENDIF}
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetBitmapName(const Value: String);
    procedure SetShowImage(const Value: Boolean);
    function GetVersion: String;
    procedure SetShowText(const Value: Boolean);
    function GetFont: TTMSFNCGraphicsFont;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    function GetWordWrapping: Boolean;
    procedure SetWordWrapping(const Value: Boolean);
    function GetImageAlign: TTMSFNCButtonAlignLayout;
    function GetTextAlign: TTMSFNCButtonAlignLayout;
    procedure SetImageAlign(const Value: TTMSFNCButtonAlignLayout);
    procedure SetTextAlign(const Value: TTMSFNCButtonAlignLayout);
    procedure SetBitmapSize(const Value: integer);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    {$IFDEF CMNWEBLIB}
    function GetText: String;
    {$ENDIF}
  protected
    {$IFNDEF WEBLIB}
    procedure ReadOldFontColor(Reader: TReader);
    procedure WriteOldFontColor(Writer: TWriter);
    procedure DefineProperties(Filer: TFiler); override;
    {$ENDIF}
    procedure SetText(const Value: string); {$IFDEF FMXLIB}override;{$ENDIF}
    {$IFDEF FMXLIB}
    function GetDefaultStyleLookupName: string; override;
    procedure ApplyStyle; override;
    {$ENDIF}
    {$IFDEF VCLLIB}
    procedure CMEnabledChanged(var Message: TMessage); message CM_ENABLEDCHANGED;
    procedure WMSetText(var Message: TWMSetText); message WM_SETTEXT;
    {$ENDIF}
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure BitmapChanged(Sender: TObject);
    {$IFDEF LCLLIB}
    procedure WSSetText(const {%H-}AText: String); override;
    function ChildClassAllowed(ChildClass: TClass): Boolean; override;
    procedure InvalidateBitmap;
    procedure DoBeforeDrawText(Sender: TObject; AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF; var {%H-}ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawImage(Sender: TObject; AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF; var {%H-}ADefaultDraw: Boolean); virtual;
    procedure DoInvalidateTimer(Sender: TObject); virtual;
    {$ENDIF}
    {$IFDEF FMXLIB}
    procedure SetEnabled(const Value: Boolean); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure SetEnabled(Value: Boolean); override;
    {$ENDIF}
    {$IFDEF WEBLIB}
    procedure UpdateElementData; override;
    procedure UpdateInnerHTML;
    procedure ParseHTMLFontTag(var ATxt: string);
    procedure ParseHTMLImgTag(var ATxt: string);
    function PosTag(ATag, ATxt: string): Integer;
    function GetHTMLCaption: string;
    function GetAttributeValue(AAttribute, ATxt: string): string;
    {$ENDIF}
  public
    {$IFDEF FMXLIB}
    property TintObject;
    property IconTintObject;
    procedure RecalcEnabled; override;
    {$ENDIF}
    function HTMLText: TTMSFNCHTMLText;
    function Image: TTMSFNCImage;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    {$IFDEF FMXLIB}
    property ImageAlign: TTMSFNCButtonAlignLayout read GetImageAlign write SetImageAlign default TAlignLayout.Left;
    property TextAlign: TTMSFNCButtonAlignLayout read GetTextAlign write SetTextAlign default TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    property ImageAlign: TTMSFNCButtonAlignLayout read GetImageAlign write SetImageAlign default alLeft;
    property TextAlign: TTMSFNCButtonAlignLayout read GetTextAlign write SetTextAlign default alClient;
    {$ENDIF}
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property BitmapName: String read FBitmapName write SetBitmapName;
    property BitmapSize: integer read FBitmapSize write SetBitmapSize default 30;
    property ShowImage: Boolean read FShowImage write SetShowImage default False;
    property ShowText: Boolean read FShowText write SetShowText default True;
    property Version: String read GetVersion;
    property Font: TTMSFNCGraphicsFont read GetFont write SetFont;
    property WordWrapping: Boolean read GetWordWrapping write SetWordWrapping default False;
    {$IFDEF CMNWEBLIB}
    property Text: String read GetText write SetText;
    {$ENDIF}
    {$IFDEF FMXLIB}
    property StaysPressed default False;
    property Action;
    property Size;
    property Align default TAlignLayout.None;
    property Anchors;
    property AutoTranslate default True;
    property CanFocus default True;
    property CanParentFocus;
    property ClipChildren default False;
    property ClipParent default False;
    property Cursor default crDefault;
    property DisableFocusEffect;
    property DragMode default TDragMode.dmManual;
    property EnableDragHighlight default True;
    property Enabled default True;
    property StyledSettings;
    property Height;
    property HelpContext;
    property HelpKeyword;
    property HelpType;
    property HitTest default True;
    property IsPressed default False;
    property Locked default False;
    property Padding;
    property ModalResult default mrNone;
    property Opacity;
    property Margins;
    property PopupMenu;
    property Position;
    property RepeatClick default False;
    property RotationAngle;
    property RotationCenter;
    property Scale;
    property StyleLookup;
    property TabOrder;
    property TabStop;
    property TintColor;
    property IconTintColor;
    property Text;
    property TouchTargetExpansion;
    property Visible default True;
    property Width;
    property OnApplyStyleLookup;
    property OnDragEnter;
    property OnDragLeave;
    property OnDragOver;
    property OnDragDrop;
    property OnDragEnd;
    property OnKeyDown;
    property OnKeyUp;
    property OnCanFocus;
    property OnClick;
    property OnDblClick;
    property OnEnter;
    property OnExit;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
    property OnMouseWheel;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnPainting;
    property OnPaint;
    property OnResize;
    {$ENDIF}
    {$IFDEF VCLLIB}
    property Action;
    property Align;
    property Anchors;
    property BiDiMode;
    property Cancel;
    property CommandLinkHint;
    property Constraints;
    property Default;
    property DisabledImageIndex;
    property DoubleBuffered;
    property DragCursor;
    property DragKind;
    property DragMode;
    property DropDownMenu;
    property ElevationRequired;
    property Enabled;
    property ModalResult;
    property ParentBiDiMode;
    property ParentDoubleBuffered;
    property ParentFont;
    property ParentShowHint;
    property PopupMenu;
    property ShowHint;
    property Style;
    property TabOrder;
    property TabStop;
    property Visible;
    property StyleElements;
    property OnClick;
    property OnContextPopup;
    property OnDragDrop;
    property OnDragOver;
    property OnDropDownClick;
    property OnEndDock;
    property OnEndDrag;
    property OnEnter;
    property OnExit;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseActivate;
    property OnMouseDown;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnMouseMove;
    property OnMouseUp;
    property OnStartDock;
    property OnStartDrag;
    {$ENDIF}
    {$IFDEF LCLLIB}
    property Action;
    property Align;
    property Anchors;
    property AutoSize;
    property BidiMode;
    property BorderSpacing;
    property Cancel;
    property Color;
    property Constraints;
    property Default;
    property DragCursor;
    property DragKind;
    property DragMode;
    property Enabled;
    property ParentBidiMode;
    property ModalResult;
    property OnChangeBounds;
    property OnClick;
    property OnContextPopup;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnEnter;
    property OnExit;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnMouseMove;
    property OnMouseUp;
    property OnMouseWheel;
    property OnMouseWheelDown;
    property OnMouseWheelUp;
    property OnResize;
    property OnStartDrag;
    property OnUTF8KeyPress;
    property ParentFont;
    property ParentShowHint;
    property PopupMenu;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Visible;
    {$ENDIF}
  end;

implementation

uses
  WEBLib.TMSFNCUtils, SysUtils
  {$IFDEF FMXLIB}
  ,FMX.Objects
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,web, Math, StrUtils
  {$ENDIF}
  ;

type
  TTMSFNCImageOpen = class(TTMSFNCImage);
  TTMSFNCHTMLTextOpen = class(TTMSFNCHTMLText);

{ TTMSFNCButton }

{$IFDEF LCLLIB}
function TTMSFNCButton.ChildClassAllowed(ChildClass: TClass): Boolean;
begin
  if (ChildClass = TTMSFNCHTMLText) or (ChildClass = TTMSFNCImage) then
    Result := True
  else
    inherited;
end;

procedure TTMSFNCButton.InvalidateBitmap;
var
  pt, pi: TWinControl;
begin
  if not Assigned(FText) or not Assigned(FImage) then
    Exit;

  if Assigned(FButtonImage) then
  begin
    FButtonImage.Free;
    FButtonImage := nil;
    Exit;
  end;

  pt := FText.Parent;
  pi := FImage.Parent;

  FText.Parent := nil;
  FImage.Parent := nil;

  FButtonImage := MakeScreenshot;

  FText.Parent := pt;
  FImage.Parent := pi;

  if Assigned(FText) then
    FText.Invalidate;

  if Assigned(FImage) then
    FImage.Invalidate;
end;

procedure TTMSFNCButton.DoBeforeDrawText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(FText) and Assigned(FButtonImage) then
    AGraphics.DrawBitmapPart(RectF(FText.Left, FText.Top, FText.Left + FText.Width, FText.Top + FText.Height), RectF(0, 0, FButtonImage.Width, FButtonImage.Height), FButtonImage, False, False, False);
end;

procedure TTMSFNCButton.DoBeforeDrawImage(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(FImage) and Assigned(FButtonImage) then
    AGraphics.DrawBitmapPart(RectF(FImage.Left, FImage.Top, FImage.Left + FImage.Width, FImage.Top + FImage.Height), RectF(0, 0, FButtonImage.Width, FButtonImage.Height), FButtonImage, False, False, False);
end;

procedure TTMSFNCButton.DoInvalidateTimer(Sender: TObject);
begin
  InvalidateBitmap;
end;

{$ENDIF}

procedure TTMSFNCButton.BitmapChanged(Sender: TObject);
var
  shp: TTMSFNCImage;
begin
  shp := FImage;
  if Assigned(shp) then
    shp.Bitmap.Assign(Bitmap);

  {$IFDEF WEBLIB}
  UpdateInnerHTML;
  {$ENDIF}

  if (csDesigning in ComponentState) then
    ShowImage := not IsBitmapEmpty(Bitmap);
end;

constructor TTMSFNCButton.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF CMNLIB}
  ControlStyle := ControlStyle - [csSetCaption];
  {$ENDIF}
  FImage := TTMSFNCImage.Create(Self);
  TTMSFNCImageOpen(FImage).Transparent := True;
  FImage.DisableBackground;
  FImage.HitTest := False;
  FImage.AllowFocus := False;
  {$IFDEF FMXLIB}
  FImage.Align := TAlignLayout.Left;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FImage.Align := alLeft;
  {$ENDIF}
  {$IFDEF VCLLIB}
  FImage.AlignWithMargins := True;
  {$ENDIF}
  {$IFNDEF LCLLIB}
  FImage.Margins.Left := 2;
  FImage.Margins.Right := 2;
  FImage.Margins.Top := 2;
  FImage.Margins.Bottom := 2;
  {$ENDIF}
  {$IFDEF LCLLIB}
  FImage.OnBeforeDraw := DoBeforeDrawImage;
  FImage.BorderSpacing.Left := 2;
  FImage.BorderSpacing.Right := 2;
  FImage.BorderSpacing.Top := 2;
  FImage.BorderSpacing.Bottom := 2;
  {$ENDIF}
  FImage.Width := 30;
  FImage.Stored := False;
  FImage.Parent := nil;

  FBitmapSize := 30;

  FText := TTMSFNCHTMLText.Create(Self);
  FText.AllowFocus := False;
  TTMSFNCHTMLTextOpen(FText).Transparent := True;
  FText.DisableBackground;
  FText.HitTest := False;
  {$IFDEF FMXLIB}
  FText.Align := TAlignLayout.Client;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FText.Align := alClient;
  {$ENDIF}
  {$IFDEF VCLLIB}
  FText.AlignWithMargins := True;
  {$ENDIF}
  {$IFNDEF LCLLIB}
  FText.Margins.Left := 2;
  FText.Margins.Right := 2;
  FText.Margins.Top := 2;
  FText.Margins.Bottom := 2;
  {$ENDIF}
  {$IFDEF LCLLIB}
  FText.OnBeforeDraw := DoBeforeDrawText;
  FText.BorderSpacing.Left := 2;
  FText.BorderSpacing.Right := 2;
  FText.BorderSpacing.Top := 2;
  FText.BorderSpacing.Bottom := 2;
  {$ENDIF}
  FText.Parent := Self;
  FText.Stored := False;

  FOldFontColor := gcNull;

  FShowImage := False;
  FShowText := True;
  FBitmap := TTMSFNCBitmap.Create;
  FBitmap.OnChange := @BitmapChanged;

  {$IFDEF LCLLIB}
  FInvalidateTimer := TTimer.Create(Self);
  FInvalidateTimer.OnTimer := DoInvalidateTimer;
  FInvalidateTimer.Interval := 1;
  FInvalidateTimer.Enabled := True;
  {$ENDIF}

  Width := 100;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCButton.WriteOldFontColor(Writer: TWriter);
{$IFDEF FMXLIB}
var
  s: String;
{$ENDIF}
begin
  {$IFDEF FMXLIB}
  AlphaColorToIdent(FOldFontColor, s);
  Writer.WriteIdent(s);
  {$ELSE}
  Writer.WriteInteger(FOldFontColor);
  {$ENDIF}
end;

procedure TTMSFNCButton.ReadOldFontColor(Reader: TReader);
var
  c: Integer;
begin
  {$IFDEF FMXLIB}
  IdentToAlphaColor(Reader.ReadIdent, c);
  {$ELSE}
  c := Reader.ReadInteger;
  {$ENDIF}

  FOldFontColor := c;
end;

procedure TTMSFNCButton.DefineProperties(Filer: TFiler);
begin
  inherited;
  Filer.DefineProperty('OldFontColor', ReadOldFontColor, WriteOldFontColor, FOldFontColor <> gcNull);
end;
{$ENDIF}

destructor TTMSFNCButton.Destroy;
begin
  {$IFDEF LCLLIB}
  if Assigned(FInvalidateTimer) then
    FInvalidateTimer.Free;

  if Assigned(FButtonImage) then
  begin
    FButtonImage.Free;
    FButtonImage := nil;
  end;
  {$ENDIF}

  FBitmap.Free;
  FImage.Free;
  FText.Free;
  inherited;
end;

{$IFDEF CMNWEBLIB}
function TTMSFNCButton.GetText: String;
var
  htmltxt: TTMSFNCHTMLText;
begin
  Result := '';
  htmltxt := FText;
  if Assigned(htmltxt) then
    Result := htmltxt.Text;
end;
{$ENDIF}

{$IFDEF VCLLIB}
procedure TTMSFNCButton.WMSetText(var Message: TWMSetText);
begin
  if FBlockTextChange then
  begin
    inherited;
    Exit;
  end;

  FBlockTextChange := True;
  Text := Message.Text;
  Message.Text := '';
  Invalidate;
  FBlockTextChange := False;
  inherited;
end;

procedure TTMSFNCButton.CMEnabledChanged(var Message: TMessage);
begin
  inherited;
end;
{$ENDIF}

{$IFDEF LCLLIB}
procedure TTMSFNCButton.WSSetText(const AText: string);
begin
  inherited WSSetText('');
end;
{$ENDIF}

procedure TTMSFNCButton.SetText(const Value: string);
var
  htmltxt: TTMSFNCHTMLText;
begin
  {$IFDEF FMXLIB}
  inherited;
  {$ENDIF}
  {$IFDEF VCLLIB}
  if HandleAllocated and not FBlockTextChange then
  begin
    FBlockTextChange := True;
    SendMessage(Handle, WM_SETTEXT, 0, Integer(PChar('')));
    FBlockTextChange := False;
  end;
  {$ENDIF}
  htmltxt := FText;
  if Assigned(htmltxt) then
    htmltxt.Text := Value;

  {$IFDEF WEBLIB}
  UpdateInnerHTML;
  {$ENDIF}
end;

{$IFDEF FMXLIB}
procedure TTMSFNCButton.RecalcEnabled;
var
  shp: TTMSFNCImage;
begin
  inherited;
  shp := FImage;
  if Assigned(shp) then
  begin
    if Enabled then
      shp.Opacity := 1
    else
      shp.Opacity := 0.5;
  end;
end;

procedure TTMSFNCButton.ApplyStyle;
var
  txt: TFmxObject;
begin
  inherited;
  txt := FindStyleResource('text');
  if Assigned(txt) and (txt is TText) then
  begin
    (txt as TText).Parent := nil;
    if Enabled then
      FText.Font.Color := (txt as TText).Color
    else
      FText.Font.Color := gcMedGray;
  end;
end;

function TTMSFNCButton.GetDefaultStyleLookupName: string;
begin
  Result := 'buttonstyle';
end;
{$ENDIF}

{$IFDEF FMXLIB}
procedure TTMSFNCButton.SetEnabled(const Value: Boolean);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCButton.SetEnabled(Value: Boolean);
{$ENDIF}
begin
  inherited;
  if Value then
  begin
    if FOldFontColor <> gcNull then
    begin
      FText.Font.Color := FOldFontColor;
      FOldFontColor := gcNull;
    end;
  end
  else
  begin
    if FOldFontColor = gcNull then
      FOldFontColor := FText.Font.Color;
    FText.Font.Color := gcMedGray;
  end;

  Invalidate;
end;

function TTMSFNCButton.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCButton.GetFont: TTMSFNCGraphicsFont;
begin
  Result := FText.Font;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCButton.UpdateElementData;
begin
  inherited;
  UpdateInnerHTML;
end;

procedure TTMSFNCButton.UpdateInnerHTML;
begin
  if Assigned(ElementHandle) then
    ElementHandle.innerHTML := GetHTMLCaption;
end;

function TTMSFNCButton.GetAttributeValue(AAttribute, ATxt: string): string;
var
  posStart, posEnd: Integer;
  tmp: string;
begin
  Result := '';
  posStart := PosTag(AAttribute, ATxt);
  if posStart > 0 then
  begin
    tmp := Copy(ATxt, posStart + length(AAttribute) + 2, length(ATxt) - posStart);
    posEnd := PosTag('"', tmp);
    Result := Copy(tmp, 1, posEnd - 1);
  end;
end;

function TTMSFNCButton.GetHTMLCaption: string;
var
  img, txt, wordw, disabled: string;
  imgwh, bmph, bmpw: Integer;
  htmltxt: TTMSFNCHTMLText;
  bmp: TTMSFNCBitmap;
  el: TJSHTMLElement;
begin
  Result := '';
  img := '';
  txt := '';
  disabled := '';

  if not Enabled then
    disabled := 'disabled';

  wordw := '';
  if not WordWrapping then
    wordw := 'text-overflow:clip; overflow:hidden; white-space:nowrap;';

  bmpw := 0;
  bmph := 0;
  el := ElementHandle;
  asm
    function property(e, p) {
      return parseInt(window.getComputedStyle(e, null).getPropertyValue(p));
    }

    var paddingTop = property(el, 'padding-top');
    var paddingBottom = property(el, 'padding-bottom');
    var paddingLeft = property(el, 'padding-left');
    var paddingRight = property(el, 'padding-right');
    bmpw = el.clientHeight - paddingTop - paddingBottom;
    bmph = el.clientWidth - paddingLeft - paddingRight;
  end;

  bmpw := Min(FBitmapSize, bmpw);
  bmph := Min(FBitmapSize, bmph);

  imgwh := 0;
  if ShowImage then
    imgwh := FBitmapSize;

  htmltxt := FText;
  if Assigned(htmltxt) then
  begin
    txt := htmltxt.Text;
    ParseHTMLFontTag(txt); //font to span
    ParseHTMLImgTag(txt); //img source
  end;

  bmp := FImage.GetBitmap;
  if Assigned(bmp) then
    img := TJSHTMLImageElement(bmp.Image).src;

  case FImage.Align of
    alLeft, alRight, alClient:
    begin
      img := '<img id="test_id" src="' + img + '" style="max-width:' + IntToStr(FBitmapSize) + 'px; max-height:' + IntToStr(bmpw) + 'px; width:auto; height:auto; display:inline-block; vertical-align: middle;" />';
      txt := '<span style="display:inline-block; vertical-align:middle; width:calc(100% - ' + IntToStr(imgwh) + 'px); ' + wordw + disabled + '">' + txt + '</span>';
    end;
    alTop, alBottom:
    begin
      img := '<img src="' + img + '" style="max-width:' + IntToStr(bmph) + 'px; max-height:' + IntToStr(FBitmapSize) + 'px; width:auto; height:auto; display:inline-block;" />';
      txt := '<div style="position:relative; height:calc(100% - ' + IntToStr(imgwh) + 'px)"><span style="position:absolute; left:50%; top:50%; transform:translate(-50%, -50%);' + wordw + disabled + '">' + txt + '</span></div>';
    end;
    alNone:
      txt := '<span style="display:inline-block; vertical-align:middle; width:100%; ' + wordw + disabled + '">' + txt + '</span>';
  end;

  case FImage.Align of
    alLeft, alTop:
    begin
      if ShowImage then
        Result := img;

      if ShowText then
        Result := Result + txt;
    end;
    alRight, alBottom:
    begin
      if ShowText then
        Result := txt;

      if ShowImage then
        Result := Result + img;
    end;
    alClient:
    begin
      if ShowImage then
        Result := img;
    end;
    alNone:
    begin
      if ShowText then
        Result := txt;
    end;
  end;
end;

procedure TTMSFNCButton.ParseHTMLFontTag(var ATxt: string);
var
  startPos, endPos: Integer;
  fontTag, spanTag, family, size, bgColor, color: string;
begin
  while ContainsText(ATxt, '<font') do
  begin
    startPos := PosTag('<font', ATxt);
    fontTag := Copy(ATxt, startPos, Length(ATxt) - startPos);
    endPos := PosTag('>', fontTag);
    fontTag := Copy(fontTag, 1, endPos);

    family := GetAttributeValue('face', fontTag);
    if Length(family) > 0 then
      family := 'font-family:' + family + ';';

    size := GetAttributeValue('size', fontTag);
    if Length(size) > 0 then
      size := 'font-size:' + size + 'px;';

    color := GetAttributeValue(' color', fontTag);
    if Length(color) > 0 then
    begin
      if Pos('gc', color) = 1 then
        color := TTMSFNCGraphics.ColorToHTML(TTMSFNCGraphics.TextToColor(color));

      color := 'color:' + color + ';';
    end;

    bgColor := GetAttributeValue('bgcolor', fontTag);
    if Length(bgColor) > 0 then
    begin
      if Pos('gc', bgColor) = 1 then
        bgColor := TTMSFNCGraphics.ColorToHTML(TTMSFNCGraphics.TextToColor(bgColor));

      bgColor := 'background-color:' + bgColor + ';';
    end;

    spanTag := '<span style="' + family + size + color + bgColor + '">';
    ATxt := StringReplace(ATxt, fontTag, spanTag, [rfReplaceAll]);
  end;
  ATxt := StringReplace(ATxt, '</font>', '</span>', [rfReplaceAll]);
end;

procedure TTMSFNCButton.ParseHTMLImgTag(var ATxt: string);
var
  startPos, endPos: Integer;
  attr, imgSrc, parsed, tmp: string;
  b: TTMSFNCBitmap;
begin
  startPos := PosTag('<img', ATxt);
  if startPos > 0 then
  begin
    parsed := Copy(ATxt, startPos + 4, Length(ATxt) - startPos);
    tmp := parsed;
    ParseHTMLImgTag(parsed);
    ATxt := StringReplace(ATxt, tmp, parsed, [rfReplaceAll]);
    endPos := PosTag('>', parsed);
    parsed := Copy(parsed, 1, endPos);
    attr := GetAttributeValue('src', parsed);
    if attr <> '' then
    begin
      if Assigned(FBitmapContainer) then
      begin
        b := FBitmapContainer.FindBitmap(attr);
        if Assigned(b) then
        begin
          imgSrc := TJSHTMLImageElement(b.Image).src;
          if imgSrc <> '' then
            ATxt := StringReplace(ATxt, attr, imgSrc, [rfReplaceAll]);
        end;
      end;
    end;
  end;
end;

function TTMSFNCButton.PosTag(ATag, ATxt: string): Integer;
begin
  ATag := UpperCase(ATag);
  ATxt := UpperCase(ATxt);
  Result := Pos(ATag, ATxt);
end;
{$ENDIF}

function TTMSFNCButton.GetImageAlign: TTMSFNCButtonAlignLayout;
begin
  Result := FImage.Align;
end;

function TTMSFNCButton.GetTextAlign: TTMSFNCButtonAlignLayout;
begin
  Result := FText.Align;
end;

function TTMSFNCButton.GetVersion: String;
{$IFDEF WEBLIB}
var
  vn: Integer;
{$ENDIF}
begin
  {$IFDEF WEBLIB}
  vn := MakeLong(MakeWord(BLD_VER, REL_VER),MakeWord(MIN_VER, MAJ_VER));
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
  {$ELSE}
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
  {$ENDIF}
end;

function TTMSFNCButton.GetWordWrapping: Boolean;
begin
  Result := FText.WordWrapping;
end;

function TTMSFNCButton.HTMLText: TTMSFNCHTMLText;
begin
  Result := FText;
end;

function TTMSFNCButton.Image: TTMSFNCImage;
begin
  Result := FImage;
end;

procedure TTMSFNCButton.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCButton.SetBitmap(const Value: TTMSFNCBitmap);
var
  shp: TTMSFNCImage;
begin
  FBitmap.Assign(Value);
  shp := FImage;
  if Assigned(shp) then
    shp.Bitmap.Assign(Value);
end;

procedure TTMSFNCButton.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
var
  shp: TTMSFNCImage;
  t: TTMSFNCHTMLText;
begin
  FBitmapContainer := Value;
  shp := FImage;
  if Assigned(shp) then
    shp.BitmapContainer := Value;

  t := FText;
  if Assigned(t) then
    t.BitmapContainer := Value;
end;

procedure TTMSFNCButton.SetBitmapName(const Value: String);
var
  shp: TTMSFNCImage;
begin
  FBitmapName := Value;
  shp := FImage;
  if Assigned(shp) then
  begin
    shp.Bitmaps.Clear;
    shp.Bitmaps.AddBitmapName(Value);
  end;

  if (csDesigning in ComponentState) then
    ShowImage := Value <> '';
end;

procedure TTMSFNCButton.SetBitmapSize(const Value: integer);
begin
  FBitmapSize := Value;
  FImage.Width := Value;
  {$IFDEF WEBLIB}
  UpdateInnerHTML;
  {$ENDIF}
end;

procedure TTMSFNCButton.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FText.Font.Assign(Value);
end;

procedure TTMSFNCButton.SetImageAlign(const Value: TTMSFNCButtonAlignLayout);
begin
  {$IFDEF CMNWEBLIB}
  if Value = alClient then
  {$ENDIF}
  {$IFNDEF CMNWEBLIB}
  if Value = TAlignLayout.Client then
  {$ENDIF}
    FOldImageWidth := FImage.Width;

  FImage.Align := Value;

  {$IFDEF CMNWEBLIB}
  if Value <> alClient then
  {$ENDIF}
  {$IFNDEF CMNWEBLIB}
  if Value <> TAlignLayout.Client then
  {$ENDIF}
    FImage.Width := FOldImageWidth;

  {$IFDEF WEBLIB}
  UpdateInnerHTML;
  {$ENDIF}
end;

procedure TTMSFNCButton.SetShowImage(const Value: Boolean);
var
  shp: TTMSFNCImage;
begin
  FShowImage := Value;
  shp := FImage;
  if Assigned(shp) then
  begin
    {$IFNDEF WEBLIB}
    if FShowImage then
      shp.Parent := Self
    else
      shp.Parent := nil;
    {$ENDIF}
    {$IFDEF WEBLIB}
    UpdateInnerHTML;
    {$ENDIF}
  end;
end;

procedure TTMSFNCButton.SetShowText(const Value: Boolean);
var
  shp: TTMSFNCHTMLText;
begin
  FShowText := Value;
  shp := FText;
  if Assigned(shp) then
  begin
    {$IFNDEF WEBLIB}
    if FShowText then
      shp.Parent := Self
    else
      shp.Parent := nil;
    {$ENDIF}
    {$IFDEF WEBLIB}
    UpdateInnerHTML;
    {$ENDIF}
  end;
end;

procedure TTMSFNCButton.SetTextAlign(const Value: TTMSFNCButtonAlignLayout);
begin
  FText.Align := Value;
end;

procedure TTMSFNCButton.SetWordWrapping(const Value: Boolean);
begin
  FText.WordWrapping := Value;
  {$IFDEF WEBLIB}
  UpdateInnerHTML;
  {$ENDIF}
end;

end.
