unit uNetworkCanvas;

interface

Uses SysUtils, Classes, WEBLib.Graphics, Types, WEBLib.Dialogs, uNetwork, uDrawTypes, uDrawReaction, uNetworkTypes;

type
  TNetworkCanvas = class

  private
      function  getControlRects (x, y, w, h : integer) : TRectArray;
      procedure drawMouseGrabPoints (x, y, w, h : integer);

  public
     network : TNetwork;
     //canvas : TCanvas;
     reactionRenderer : TReactionRender;

     bitmap : TBitmap;
     procedure paint (origin : TPointF; scaleFactor : double);

     procedure drawReactions (origin : TPointF; scalingFactor : double);
     procedure drawNodes (origin : TPointF; scalingFactor : double);
     constructor Create (network : TNetwork);// Canvas : TCanvas);

  end;

implementation


constructor TNetworkCanvas.Create (network : TNetwork);// canvas : TCanvas);
begin
  //self.canvas := canvas;
  bitmap := TBitmap.Create;
  self.network := network;
  reactionRenderer := TReactionRender.Create (bitmap.canvas);
end;


procedure TNetworkCanvas.paint (origin : TPointF; scaleFactor : double);
var dest : TRect;
begin
  dest.Left := 0; dest.top := 0;
  dest.Right := bitmap.Width-0; dest.Bottom := bitmap.Height-0;
  bitmap.canvas.pen.color := clWhite;
  bitmap.canvas.Brush.Color := clWhite;
  bitmap.canvas.FillRect(dest);

  drawNodes (origin, scaleFactor);
  drawReactions (origin, scaleFactor);
end;



// Assumes that x, y, w and h have been prescaled
function TNetworkCanvas.getControlRects (x, y, w, h : integer) : TRectArray;
var grabW,  grabH : integer;
    grabW2, grabH2 : integer;
begin
  grabW := trunc (6);
  grabH := trunc (6);
  grabW2 := grabW div 2;
  grabH2 := grabH div 2;

  result[0] := rect (x - grabW2,  y - grabW2, x + grabW2, y + grabH2);
  result[1] := rect (x + w - grabW2, y - grabH2, x + w + grabW2, y + grabH2);
  result[2] := rect (x - grabW2, y + h - grabH2, x + grabW2, y + h + grabH2);
  result[3] := rect (x + w - grabW2, y + h - grabH2, x + w + grabW2, y + h + grabH2);
end;



procedure TNetworkCanvas.drawMouseGrabPoints (x, y, w, h : integer);
var  scalingFactor : double;
     rectList : TRectArray;
begin
  rectList := getControlRects(x, y, w, h);

  bitmap.canvas.brush.color := clRed;
  bitmap.canvas.brush.style := bsSolid;
  bitmap.Canvas.FillRect (rectList[0]);
  bitmap.Canvas.FillRect (rectList[1]);
  bitmap.Canvas.FillRect (rectList[2]);
  bitmap.Canvas.FillRect (rectList[3]);
end;


procedure TNetworkCanvas.drawNodes (origin : TPointF; scalingFactor : double);
var i : integer;
    oldWidth : integer;
    oldColor : TColor;
    f, sX, sY : integer;
    scaledX, scaledY, scaledW, scaledH : integer;
begin
  oldWidth := bitmap.canvas.pen.width;
  oldColor := bitmap.canvas.pen.color;

  try
    for i := 0 to length (network.nodes) - 1 do
        begin
       // Compute the scaled values, adjusting for any change in the origin
       scaledX := trunc (network.nodes[i].state.x*scalingFactor - origin.x);
       scaledY := trunc (network.nodes[i].state.y*scalingFactor - origin.y);
       scaledW := trunc (network.nodes[i].state.w*scalingFactor);
       scaledH := trunc (network.nodes[i].state.h*scalingFactor);

        if network.nodes[i].selected then
           begin
           bitmap.canvas.pen.color := clRed;
           bitmap.canvas.pen.width := 1;
           bitmap.canvas.brush.style := bsClear;
           f := trunc (4*scalingFactor);

           sX := trunc (scaledX) - f;
           sY := trunc (scaledY) - f;

           bitmap.canvas.Rectangle (sX, sY, sX + scaledW + 2*f, sY + scaledH + 2*f);
           drawMouseGrabPoints (sX, sY, scaledW + 2*f, scaledH + 2*f);
           end;

        if network.nodes[i].addReactionSelected then
           begin
           bitmap.canvas.pen.color := clRed;
           bitmap.canvas.brush.style := bsClear;
           bitmap.canvas.pen.width := 1;
           bitmap.canvas.pen.style := psDash;
           bitmap.canvas.RoundRect((network.nodes[i].state.x - 7)*scalingFactor - origin.x,
                (network.nodes[i].state.y - 7)*scalingFactor - origin.y,
                scaledX + (network.nodes[i].state.w + 7)*scalingFactor ,
                scaledY + (network.nodes[i].state.h + 7)*scalingFactor, 25, 25);//DX div 2, DY div 2);

           bitmap.canvas.pen.style := psSolid;
           bitmap.canvas.pen.color := network.nodes[i].state.outlineColor;
           end;

        bitmap.canvas.pen.color := network.nodes[i].state.outlineColor;
        bitmap.canvas.pen.width := 3;
        bitmap.canvas.brush.color := network.nodes[i].state.fillColor;
        bitmap.canvas.brush.style := bsSolid;
        bitmap.canvas.RoundRect(scaledX, scaledY,
                scaledX + network.nodes[i].state.w*scalingFactor,
                scaledY + network.nodes[i].state.h*scalingFactor, 25, 25);//DX div 2, DY div 2);
        end;
  finally
     bitmap.canvas.pen.width := oldWidth;
     bitmap.canvas.pen.color := oldColor;
     bitmap.canvas.pen.style := psSolid;
  end;
end;


procedure TNetworkCanvas.drawReactions (origin : TPointF; scalingFactor : double);
var i : integer;
    scaledLineThickness : integer;
    //pSrc, pDest : TPoint;
begin
  bitmap.canvas.pen.width := 2;
  try
    for i := 0 to length (network.reactions) - 1 do
        begin
        if network.reactions[i].selected then
           begin
           bitmap.canvas.pen.color := clRed;
           end
        else
           begin
           bitmap.canvas.pen.width := trunc (2 * scalingFactor);
           bitmap.canvas.pen.color := clBlack;
           end;
        reactionRenderer.draw (origin, scalingFactor, network.reactions[i]);
      end;
  finally
      bitmap.canvas.pen.color := clBlack;
  end;
end;


end.
