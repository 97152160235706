unit uLayout;

interface

Uses Classes, WEBLib.Graphics, uNetwork, WEBLib.Dialogs;

type
  TCallBack = procedure of object;

  procedure fruchterman_reingold(network : TNetwork; width, height : integer; iterations : integer; callBack : TCallBack);

implementation

Uses sysutils, math, ufMain;

const
  enumber = 2.7182818284590452353;

var N : double = 1000;
    k : double = 5;
    P : double = 0.05;


// attractive force
function f_attractive (d, k : double) : double;
begin
    result := d*d/k;
end;

// repulsive force
function f_repulsive (d, k : double) : double;
begin
    result := k*k/d;
end;


// Translated from Annastasia Deckard's C# code of autolayout in SBW

procedure fruchterman_reingold(network : TNetwork; width, height : integer; iterations : integer; callBack : TCallBack);
var W, L, area : double;
    k, dt, t, x, y : double;
    i : integer;
    dx, dy, ddx, ddy, delta, disp, d: double;
    v, u, m : integer;
    tempinit, alpha : double;
    finished : boolean;
    iter : integer;
    maxIter : integer;
begin
  finished := False;
  iter := 0;
  maxIter := 600;
  W := width;
  L := height;
  area := W*L;
  //k := sqrt(area/length (network.nodes));
  k := 120;
  iterations := trunc ((130 * ln(length (network.nodes) + 2)));

    // initial position
    //for i := 0 to length (network.nodes) - 1 do
    //    begin
    //    network.nodes[i].state.x := W*random();
    //    network.nodes[i].state.y := L*random();
    //    end;

    dt := 1/(iterations + 1);
    tempinit := 1000 * ln(length (network.reactions) + 2);
    alpha := ln(tempinit) - ln(0.25);
    //showmessage ('dt = ' + floattostr (dt));
    //showmessage ('tempinit = ' + floattostr (tempinit));
    //showmessage ('alpha = ' + floattostr (alpha));

    while not finished do
         begin
         t := tempinit * Math.Power(enumber, -alpha * time);
         if Assigned (callBack) then
            callBack;
         //sleep (10);

         // calculate repulsive forces
         for v := 0 to length(network.nodes) - 1 do
             begin
             if network.hasReactions (network.nodes[v]) then
                begin
                network.nodes[v].dx := 0;
                network.nodes[v].dy := 0;
                for u := 0 to length(network.nodes) - 1 do
                    begin
                    if network.nodes[v] <> network.nodes[u] then
                       begin
                       dx := network.nodes[v].getCenter().x - network.nodes[u].getCenter().x;
                       dy := network.nodes[v].getCenter().y - network.nodes[u].getCenter().y;
                       delta := sqrt(dx*dx+dy*dy);
                       if delta <> 0 then
                          begin
                          d := f_repulsive (delta, k)/delta;
                          network.nodes[v].dx := network.nodes[v].dx + dx*d;
                          network.nodes[v].dy := network.nodes[v].dy + dy*d;
                          end;
                       end;
                    end;
                end;
             end;

         //for v := 0 to length(network.nodes) - 1 do
         //    begin
         //    frmMain.lb.lines.add (floattostr (network.nodes[v].dx) + ', ' + floattostr (network.nodes[v].dy));
         //    end;


          // calculate attractive forces
         for m := 0 to length (network.reactions) - 1 do
             begin
             dx := network.reactions[m].state.srcPtr[0].state.x - network.reactions[m].state.destPtr[0].state.x ;
             dy := network.reactions[m].state.srcPtr[0].state.y - network.reactions[m].state.destPtr[0].state.y;
             delta := sqrt(dx*dx+dy*dy);
             if delta <> 0 then
                begin
                //adjustk = k;
								//adjustk = (k * Math.Log((v.Degree + u.Degree + 2), Math.E) + (Math.Max(v.W, v.H) + Math.Max(u.W, u.H))/4);
                d := f_attractive (delta,k)/delta;
                ddx := dx*d;
                ddy := dy*d;
                network.reactions[m].state.srcPtr[0].dx := network.reactions[m].state.srcPtr[0].dx + (-ddx);
                network.reactions[m].state.destPtr[0].dx := network.reactions[m].state.destPtr[0].dx + (+ddx);

                network.reactions[m].state.srcPtr[0].dy := network.reactions[m].state.srcPtr[0].dy + (-ddy);
                network.reactions[m].state.destPtr[0].dy := network.reactions[m].state.destPtr[0].dy + (+ddy);
                end;
            end;

        //for m := 0 to length(network.nodes) - 1 do
        //     begin
        //     frmMain.lb.lines.add (floattostr (network.reactions[m].state.srcPtr[0].dx) + ', ' + floattostr (network.reactions[m].state.destPtr[0].dx));
        //     frmMain.lb.lines.add (floattostr (network.reactions[m].state.srcPtr[0].dy) + ', ' + floattostr (network.reactions[m].state.destPtr[0].dy));
        //     //frmMain.lb.lines.add (floattostr (network.reactions[m][0].dx) + ', ' + floattostr (network.reactions[m][1].dx));
         //    frmMain.lb.lines.add ('-');
        //     end;
        // frmMain.lb.Lines.add ('B------');

				 // Adjust Coordinates
         for v := 0 to length(network.nodes) - 1 do
				     begin
             if network.hasReactions (network.nodes[v]) then
                begin
                //if not network.nodes[v].locked then
                   begin
                   dx := network.nodes[v].dx;
                   dy := network.nodes[v].dy;
                   disp := sqrt(dx*dx+dy*dy);

                   if (disp <> 0) then
                       begin
                       network.nodes[v].state.x := 0 + (network.nodes[v].state.x + ((dx/disp) * t)); // divide by d is okay
                       network.nodes[v].state.y := 0 + trunc (network.nodes[v].state.y + ((dy/disp) * t));
                       //network.nodes[v].state.x := trunc (network.nodes[v].getCenter().x + ((dx/disp) * t)); // divide by d is okay
                       //network.nodes[v].state.y := trunc (network.nodes[v].getCenter().y + ((dy/disp) * t));
                       end;
                   end;
                end;
				     end;

//          for v := 0 to length(network.nodes) - 1 do
//	  		     begin
//             dx := network.nodes[v].dx;
//             dy := network.nodes[v].dy;
//             disp := sqrt(dx*dx+dy*dy);
//             if disp <> 0 then
//                begin
//                network.nodes[v].state.x := network.nodes[v].state.x  + (network.nodes[v].dx/disp)*min (disp, t);
//                network.nodes[v].state.y := network.nodes[v].state.y  + (network.nodes[v].dy/disp)*min (disp, t);
//                network.nodes[v].state.x := min (W/2, max (-W/2, network.nodes[v].state.x));
//                network.nodes[v].state.y := min (L/2, max (-L/2, network.nodes[v].state.y));
//                end;
//             end;
//         for m := 0 to length(network.nodes) - 1 do
//             begin
//             frmMain.lb.lines.add (floattostr (network.nodes[m].state.x) + ', ' + floattostr (network.nodes[m].state.y));
//             frmMain.lb.lines.add ('-');
//             end;
//        frmMain.lb.Lines.add ('C------');


         inc (iter);
         if ((abs(dx) < 0.4) and (abs (dy) < 0.4)) or (iter > maxIter) then
            finished := True;

         // cooling
         t := t - dt;
         end;
    //showmessage (floattostr (network.nodes[1].state.x) + ', ' + floattostr (network.nodes[1].state.y));
end;


end.


//
          // limit the maximum displacement to the temperature t
         // and then prevent from being displace outside frame
//         for v := 0 to length(network.nodes) - 1 do
//             begin
//             dx := network.nodes[v].dx;
//             dy := network.nodes[v].dy;
//             disp := sqrt(dx*dx+dy*dy);
//             if disp <> 0 then
//                begin
//                //network.nodes[v].x := network.nodes[v].x*math.min (disp, t);
//                //network.nodes[v].x := network.nodes[v].y*math.min (disp, t);
//                //network.nodes[v].x := math.min(W/2, math.max(-W/2,network.nodes[v].x));
//                //network.nodes[v].y := math.min(L/2, math.max(-L/2,network.nodes[v].y));
//
//                d := math.min(disp,t)/disp;
//                x := network.nodes[v].x + dx*d;
//                y := network.nodes[v].y + dy*d;
//                x := math.min(W,math.max(0,x)) - W/2;
//                y := math.min(L, max(0,y)) - L/2;
//                network.nodes[v].x := math.min(sqrt(W*W/4-y*y),math.max(-sqrt(W*W/4-y*y),x)) + W/2;
//                network.nodes[v].y := math.min(sqrt(L*L/4-x*x),math.max(-sqrt(L*L/4-x*x),y)) + L/2;
//                end;
//             end;

