unit ufMain;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls, Types, WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.StdCtrls, uNetwork, WEBLib.Buttons, WEBLib.JQCtrls, uController, uNetworkCanvas, WEBLib.Menus, WEBLib.WebCtrls, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCScrollBar, WEBLib.TMSFNCButton, WEBLib.TMSFNCToolBar,
  uNetworkTypes, WEBLib.Imaging.pngimage;

type
  TMouseStatus = (sIdle, sAddNode, sAddEdge, sMouseDown);

  TListOfNodes = array of TNode;
  TfrmMain = class(TForm)
    pnlBottom: TPanel;
    btnDraw: TButton;
    centralPanel: TPanel;
    btnRandomNetwork: TButton;
    btnAutoLayout: TButton;
    lb: TMemo;
    btnCenter: TButton;
    pnlScroll: TPanel;
    WebPanel1: TPanel;
    scrollBarVert: TTMSFNCScrollBar;
    pnlLeft: TPanel;
    WebLabel2: TLabel;
    btnNodeFillColor: TColorPicker;
    btnNodeOutlineColor: TColorPicker;
    WebLabel1: TLabel;
    trackBarZoom: TTrackBar;
    pnlInner: TPanel;
    scrollBarHoriz: TTMSFNCScrollBar;
    paintBox: TPaintBox;
    btnAbout: TButton;
    btnNew: TButton;
    btnSave: TButton;
    WebHTMLDiv1: THTMLDiv;
    filePicker: TFilePicker;
    btnFilePicker: TButton;
    btnIdle: TSpeedButton;
    btnUniUni: TSpeedButton;
    btnUniBi: TSpeedButton;
    btnBiUni: TSpeedButton;
    btnBiBi: TSpeedButton;
    WebSpeedButton1: TSpeedButton;
    WebPanel2: TPanel;
    lblX: TLabel;
    lblY: TLabel;
    lblZoomFactor1: TLabel;
    lblZoomFactor: TLabel;
    procedure addUniBiReactionClick(Sender: TObject);
    procedure btnAboutClick(Sender: TObject);
    procedure btnAddBiBiClick(Sender: TObject);
    procedure btnAddBiUniClick(Sender: TObject);
    procedure btnAddNodeXClick(Sender: TObject);
    procedure btnAddUniUniReactionClick(Sender: TObject);
    procedure btnAutoLayoutClick(Sender: TObject);
    procedure btnCenterClick(Sender: TObject);
    procedure btnClearClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure btnDrawClick(Sender: TObject);
    procedure btnIdleClick(Sender: TObject);
    procedure btnNodeFillColorSelect(Sender: TObject);
    procedure btnNodeOutlineColorClick(Sender: TObject);
    procedure btnRandomNetworkClick(Sender: TObject);
    procedure filePickerChange(Sender: TObject);
    procedure filePickerGetFileAsText(Sender: TObject; AFileIndex: Integer; AText:
        string);
    procedure mnuOpenClick(Sender: TObject);
    procedure mnuSaveClick(Sender: TObject);
    procedure mnuUndoClick(Sender: TObject);
    procedure paintBoxKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure paintBoxMouseDown(Sender: TObject; Button: TMouseButton; Shift:
        TShiftState; X, Y: Integer);
    procedure paintBoxMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Integer);
    procedure paintBoxMouseUp(Sender: TObject; Button: TMouseButton; Shift:
        TShiftState; X, Y: Integer);
    procedure paintBoxMouseWheel(Sender: TObject; Shift: TShiftState; WheelDelta:
        Integer; MousePos: TPoint; var Handled: Boolean);
    procedure paintBoxPaint(Sender: TObject);
    procedure scrollbarHorizValueChanged(Sender: TObject; Value: Double);
    procedure scrollBarVertValueChanged(Sender: TObject; Value: Double);
    procedure trackBarZoomChange(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private declarations }
    procedure LoadFile;
  protected
    procedure LoadDFMValues; override;
  public
    { Public declarations }
    network : TNetwork;
    selectedNode : integer;
    currentX, currentY : integer;
    controller : TController;
    networkCanvas : TNetworkCanvas;
    FScale : double;
    FOrigin : TPointF;
    fileName : string;
    function ScreenToWorld (x, y : double) : TPointF;
    function WorldToScreen (wx : integer) : integer;
  end;

var
  frmMain: TfrmMain;

implementation


Uses uGraphUtils, uCreateNetworks, uLayout;


procedure TfrmMain.addUniBiReactionClick(Sender: TObject);
begin
  controller.setAddUniBiReaction;
end;

procedure TfrmMain.btnAboutClick(Sender: TObject);
begin
   Showmessage ('Version 0.15');
end;

procedure TfrmMain.btnAddBiBiClick(Sender: TObject);
begin
  controller.setAddBiBiReaction;
end;

procedure TfrmMain.btnAddBiUniClick(Sender: TObject);
begin
  controller.setAddBiUniReaction;
end;



procedure TfrmMain.LoadFile;
var FFileName : string;
begin
  if Assigned(filePicker.Files[0]) then
  begin
    filePicker.Files[0].GetFileAsText;
    FFileName := filePicker.Files[0].Name;
    end;
end;


function TfrmMain.ScreenToWorld (x, y : double) : TPointF;
begin
  result.x := (x + FOrigin.x)/FScale;
  result.y := (y + FOrigin.y)/FScale;
end;


function TfrmMain.WorldToScreen (wx : integer) : integer;
begin
 raise Exception.Create ('WorldtoScreen needs to be updated');
 result := trunc (wx*FScale);
end;


procedure TfrmMain.btnAddNodeXClick(Sender: TObject);
begin
  controller.setAddNodeStatus;
end;


procedure TfrmMain.btnAddUniUniReactionClick(Sender: TObject);
begin
  controller.setAddUniUniReaction;
end;


procedure TfrmMain.btnAutoLayoutClick(Sender: TObject);
begin
  //showmessage (inttostr (paintbox.Width) + ', ' + inttostr (paintbox.Width));
  fruchterman_reingold(network, paintbox.width, paintbox.Height, 600, nil);
  network.centerNetwork (paintBox.Width, paintbox.Height);
  paintbox.Invalidate;
end;


procedure TfrmMain.btnCenterClick(Sender: TObject);
var i : integer;
begin
  network.centerNetwork (paintbox.Width, paintbox.height);
  lb.Clear;
  for i := 0 to length (network.nodes) - 1 do
      begin
      lb.Lines.Add (inttostr (trunc (network.nodes[i].state.x)) + ', ' + inttostr (trunc (network.nodes[i].state.x)));
      end;
  paintbox.Invalidate;
end;


procedure TfrmMain.btnClearClick(Sender: TObject);
begin
  network.Clear;
  paintbox.Invalidate;
end;


procedure TfrmMain.WebFormCreate(Sender: TObject);
begin
  FScale := 1.0;
  trackBarZoom.left := 20;
  trackBarZoom.position := 10;
  FOrigin.X := 0.0; FOrigin.Y := 0.0;

  network := TNetwork.Create ('testNetwork');
  controller := TController.Create (network);
  networkCanvas := TNetworkCanvas.Create (network);
  networkCanvas.bitmap.Height := paintBox.height;
  networkCanvas.bitmap.Width := paintBox.Width;

  pnlLeft.color := clWhite;//clWebAliceBlue;

  //btnSpeed.color := clWebLightskyblue;
  //btnOpen.color := clWebLightskyblue;
 // mainMenu.Appearance.ImageURLs.Clear;
  //mainMenu.Appearance.ImageURLs.Add('undo.png');

  //filePicker.Caption := 'Hello';
  //WebMainMenu1.Appearance.ImageURLs.Add('img/16x16/Copy.png');
  //WebMainMenu1.Appearance.ImageURLs.Add('img/16x16/Cut.png');
  //WebMainMenu1.Appearance.ImageURLs.Add('img/16x16/Exit.png');
  //WebMainMenu1.Appearance.ImageURLs.Add('img/16x16/Open.png');
  //WebMainMenu1.Appearance.ImageURLs.Add('img/16x16/Paste.png');
  //WebMainMenu1.Appearance.ImageURLs.Add('img/16x16/Save.png');
end;



procedure TfrmMain.btnDrawClick(Sender: TObject);
var n1, n2, n3, n4 : TNode;
begin
  n1 := controller.addNode ('node1', 60, 200);
  n2 := controller.addNode ('node2', 270, 270);
  n3 := controller.addNode ('node3', 540, 80);
  n4 := controller.addNode ('node4', 400, 500);

  controller.addReaction ('r1', n1, n2);
  controller.addReaction ('r2', n2, n3);
  controller.addReaction ('r3', n3, n4);
  controller.addReaction ('r4', n4, n2);

  paintbox.Invalidate;
end;


procedure TfrmMain.btnIdleClick(Sender: TObject);
begin
   controller.setIdleStatus;
end;


procedure TfrmMain.btnNodeFillColorSelect(Sender: TObject);
var i : integer;
begin
  for i := 0 to length (network.nodes) - 1 do
      if network.nodes[i].selected then
         begin
         network.nodes[i].state.fillColor := btnNodeFillColor.color;
         paintbox.Invalidate;
         end;
end;


procedure TfrmMain.btnNodeOutlineColorClick(Sender: TObject);
var i : integer;
begin
  for i := 0 to length (network.nodes) - 1 do
      if network.nodes[i].selected then
         begin
         network.nodes[i].state.outlineColor := btnNodeOutlineColor.color;
         paintbox.Invalidate;
         end;
end;

procedure TfrmMain.btnRandomNetworkClick(Sender: TObject);
var nNodes : integer;
    probability : double;
    n1, n2, n3, n4 : TNode;
begin
  network.clear;
  //showmessage ('hi');
  nNodes := 12;
  probability := 0.9;
  createRandomNetwork (network, nNodes, probability);

  paintbox.Invalidate;
end;


procedure TfrmMain.filePickerChange(Sender: TObject);
begin
  LoadFile;
end;


procedure TfrmMain.filePickerGetFileAsText(Sender: TObject; AFileIndex:  Integer; AText: string);
begin
  try
     controller.loadModel (AText);
  except
    on E: exception do
       showmessage (e.message);
  end;
  paintbox.Invalidate;
end;


procedure TfrmMain.mnuOpenClick(Sender: TObject);
begin
  showmessage ('Use the choose file button to load a model');
end;


procedure TfrmMain.mnuSaveClick(Sender: TObject);
var jstr, fileName : string;
begin
  fileName := InputBox('Save model to the downloads directory', 'Enter File Name:', 'jstr.json');
  if fileName <> '' then
     begin
     jstr := controller.network.convertToJSON();
     Application.DownloadTextFile(jstr, fileName);
     end
  else
     showmessage ('Save cancelled');
end;


procedure TfrmMain.mnuUndoClick(Sender: TObject);
begin
  controller.undo;
  paintBox.invalidate;
end;

procedure TfrmMain.paintBoxKeyDown(Sender: TObject; var Key: Word; Shift:
    TShiftState);
begin
  // Handle Ctrl-keys here
  if Key = VK_DELETE then
     begin
     controller.prepareUndo;
     controller.deleteSelectedItems;
     paintBox.invalidate;
     exit;
     end;

  if (Shift = [ssCtrl]) and (Upcase (Char (Key)) = 'Z') then
     begin
     controller.Undo;
     paintBox.invalidate;
     end;
end;


procedure TfrmMain.paintBoxMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var v : TPointF;
begin
  v := screenToWorld (x, y);

  controller.OnMouseDown(Sender, Button, Shift, v.x, v.y);
  paintBox.invalidate;
end;


procedure TfrmMain.paintBoxMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Integer);
var v : TPointF;
begin
  v := screenToWorld (x, y);

  controller.OnMouseMove (Sender, Shift, v.x, v.y);
  paintbox.Invalidate;
  lblX.caption := 'X: ' + inttostr (trunc (v.x));
  lblY.caption := 'Y: ' + inttostr (trunc (v.y));
end;


procedure TfrmMain.paintBoxMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var v : TPointF;
begin
  v := screenToWorld (x, y);

  controller.OnMouseUp (Sender, Button, Shift, v.x, v.y);
  paintBox.invalidate;
end;


procedure TfrmMain.paintBoxMouseWheel(Sender: TObject; Shift: TShiftState;
    WheelDelta: Integer; MousePos: TPoint; var Handled: Boolean);
begin
  if wheelDelta < 0 then
     FOrigin.y := FOrigin.y + 20  //WheelDelta
  else
     FOrigin.y := FOrigin.y - 20; //WheelDelta

  if FOrigin.y < 0 then
     FOrigin.y := 0;

  Handled := True;
  paintBox.Invalidate;
end;


procedure TfrmMain.paintBoxPaint(Sender: TObject);
var dest : TRect;
begin
  networkCanvas.paint (FOrigin, FScale);
  //dest.Left := 0; dest.top := 0;
  //dest.Right := paintBox.Width; dest.Bottom := paintBox.Height+5;
  //paintBox.canvas.Brush.color := clWhite;
  //paintBox.canvas.FillRect(dest);
  paintBox.canvas.draw (0, 0, networkCanvas.bitmap);
  paintbox.canvas.pen.color := clWhite;
end;


procedure TfrmMain.scrollbarHorizValueChanged(Sender: TObject; Value: Double);
begin
  FOrigin.X := value;
  paintBox.Invalidate;
end;

procedure TfrmMain.scrollBarVertValueChanged(Sender: TObject; Value: Double);
begin
  lblX.caption := floattostr (value);
  FOrigin.Y := value;
  paintBox.Invalidate;
end;

procedure TfrmMain.trackBarZoomChange(Sender: TObject);
begin
  FScale := trackBarZoom.position/10;
  paintBox.invalidate;
  lblZoomFactor.caption := floattostr (trackBarZoom.position/10);
end;


procedure TfrmMain.WebFormResize(Sender: TObject);
begin
  networkCanvas.bitmap.Height := paintBox.height;
  networkCanvas.bitmap.Width := paintBox.Width;
  paintBox.invalidate;
end;

procedure TfrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pnlBottom := TPanel.Create(Self);
  btnDraw := TButton.Create(Self);
  btnRandomNetwork := TButton.Create(Self);
  btnAutoLayout := TButton.Create(Self);
  btnCenter := TButton.Create(Self);
  btnAbout := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  lblX := TLabel.Create(Self);
  lblY := TLabel.Create(Self);
  lblZoomFactor1 := TLabel.Create(Self);
  lblZoomFactor := TLabel.Create(Self);
  centralPanel := TPanel.Create(Self);
  lb := TMemo.Create(Self);
  pnlScroll := TPanel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  scrollBarVert := TTMSFNCScrollBar.Create(Self);
  pnlLeft := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  btnNodeFillColor := TColorPicker.Create(Self);
  btnNodeOutlineColor := TColorPicker.Create(Self);
  trackBarZoom := TTrackBar.Create(Self);
  btnNew := TButton.Create(Self);
  btnSave := TButton.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  filePicker := TFilePicker.Create(Self);
  btnFilePicker := TButton.Create(Self);
  btnIdle := TSpeedButton.Create(Self);
  btnUniUni := TSpeedButton.Create(Self);
  btnUniBi := TSpeedButton.Create(Self);
  btnBiUni := TSpeedButton.Create(Self);
  btnBiBi := TSpeedButton.Create(Self);
  WebSpeedButton1 := TSpeedButton.Create(Self);
  pnlInner := TPanel.Create(Self);
  scrollBarHoriz := TTMSFNCScrollBar.Create(Self);
  paintBox := TPaintBox.Create(Self);

  pnlBottom.BeforeLoadDFMValues;
  btnDraw.BeforeLoadDFMValues;
  btnRandomNetwork.BeforeLoadDFMValues;
  btnAutoLayout.BeforeLoadDFMValues;
  btnCenter.BeforeLoadDFMValues;
  btnAbout.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  lblX.BeforeLoadDFMValues;
  lblY.BeforeLoadDFMValues;
  lblZoomFactor1.BeforeLoadDFMValues;
  lblZoomFactor.BeforeLoadDFMValues;
  centralPanel.BeforeLoadDFMValues;
  lb.BeforeLoadDFMValues;
  pnlScroll.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  scrollBarVert.BeforeLoadDFMValues;
  pnlLeft.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  btnNodeFillColor.BeforeLoadDFMValues;
  btnNodeOutlineColor.BeforeLoadDFMValues;
  trackBarZoom.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  filePicker.BeforeLoadDFMValues;
  btnFilePicker.BeforeLoadDFMValues;
  btnIdle.BeforeLoadDFMValues;
  btnUniUni.BeforeLoadDFMValues;
  btnUniBi.BeforeLoadDFMValues;
  btnBiUni.BeforeLoadDFMValues;
  btnBiBi.BeforeLoadDFMValues;
  WebSpeedButton1.BeforeLoadDFMValues;
  pnlInner.BeforeLoadDFMValues;
  scrollBarHoriz.BeforeLoadDFMValues;
  paintBox.BeforeLoadDFMValues;
  try
    Name := 'frmMain';
    Left := 0;
    Top := 0;
    Width := 1392;
    Height := 905;
    CSSLibrary := cssBootstrap;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    pnlBottom.SetParentComponent(Self);
    pnlBottom.Name := 'pnlBottom';
    pnlBottom.Left := 0;
    pnlBottom.Top := 822;
    pnlBottom.Width := 1392;
    pnlBottom.Height := 83;
    pnlBottom.ElementClassName := 'card border-0';
    pnlBottom.Align := alBottom;
    pnlBottom.BorderColor := clSilver;
    pnlBottom.BorderStyle := bsNone;
    pnlBottom.Caption := '';
    pnlBottom.ChildOrder := 3;
    pnlBottom.ElementBodyClassName := 'card-body pl-10 bg-dark text-white';
    btnDraw.SetParentComponent(pnlBottom);
    btnDraw.Name := 'btnDraw';
    btnDraw.Left := 828;
    btnDraw.Top := 19;
    btnDraw.Width := 133;
    btnDraw.Height := 25;
    btnDraw.Caption := 'Draw Sample Network';
    btnDraw.ChildOrder := 1;
    SetEvent(btnDraw, Self, 'OnClick', 'btnDrawClick');
    btnRandomNetwork.SetParentComponent(pnlBottom);
    btnRandomNetwork.Name := 'btnRandomNetwork';
    btnRandomNetwork.Left := 673;
    btnRandomNetwork.Top := 17;
    btnRandomNetwork.Width := 133;
    btnRandomNetwork.Height := 25;
    btnRandomNetwork.Caption := 'Random Network';
    btnRandomNetwork.ChildOrder := 1;
    SetEvent(btnRandomNetwork, Self, 'OnClick', 'btnRandomNetworkClick');
    btnAutoLayout.SetParentComponent(pnlBottom);
    btnAutoLayout.Name := 'btnAutoLayout';
    btnAutoLayout.Left := 673;
    btnAutoLayout.Top := 48;
    btnAutoLayout.Width := 133;
    btnAutoLayout.Height := 25;
    btnAutoLayout.Caption := 'Auto Layout';
    btnAutoLayout.ChildOrder := 1;
    SetEvent(btnAutoLayout, Self, 'OnClick', 'btnAutoLayoutClick');
    btnCenter.SetParentComponent(pnlBottom);
    btnCenter.Name := 'btnCenter';
    btnCenter.Left := 509;
    btnCenter.Top := 19;
    btnCenter.Width := 133;
    btnCenter.Height := 25;
    btnCenter.Color := 14745599;
    btnCenter.Caption := 'Center';
    btnCenter.ChildOrder := 1;
    SetEvent(btnCenter, Self, 'OnClick', 'btnCenterClick');
    btnAbout.SetParentComponent(pnlBottom);
    btnAbout.Name := 'btnAbout';
    btnAbout.Left := 1055;
    btnAbout.Top := 20;
    btnAbout.Width := 96;
    btnAbout.Height := 25;
    btnAbout.Anchors := [akTop,akRight];
    btnAbout.Caption := 'About';
    btnAbout.ChildOrder := 10;
    SetEvent(btnAbout, Self, 'OnClick', 'btnAboutClick');
    WebPanel2.SetParentComponent(pnlBottom);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 16;
    WebPanel2.Top := 11;
    WebPanel2.Width := 124;
    WebPanel2.Height := 60;
    WebPanel2.ElementClassName := 'card';
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.Caption := '';
    WebPanel2.ChildOrder := 9;
    WebPanel2.ElementBodyClassName := 'card-body shadow';
    WebPanel2.ElementFont := efCSS;
    lblX.SetParentComponent(WebPanel2);
    lblX.Name := 'lblX';
    lblX.Left := 14;
    lblX.Top := 14;
    lblX.Width := 13;
    lblX.Height := 14;
    lblX.Caption := 'X: ';
    lblX.ElementClassName := 'label';
    lblY.SetParentComponent(WebPanel2);
    lblY.Name := 'lblY';
    lblY.Left := 61;
    lblY.Top := 14;
    lblY.Width := 10;
    lblY.Height := 14;
    lblY.Caption := 'Y:';
    lblY.ElementClassName := 'label';
    lblZoomFactor1.SetParentComponent(WebPanel2);
    lblZoomFactor1.Name := 'lblZoomFactor1';
    lblZoomFactor1.Left := 14;
    lblZoomFactor1.Top := 34;
    lblZoomFactor1.Width := 64;
    lblZoomFactor1.Height := 14;
    lblZoomFactor1.Caption := 'Zoom Factor ';
    lblZoomFactor1.ElementClassName := 'label';
    lblZoomFactor.SetParentComponent(WebPanel2);
    lblZoomFactor.Name := 'lblZoomFactor';
    lblZoomFactor.Left := 83;
    lblZoomFactor.Top := 34;
    lblZoomFactor.Width := 15;
    lblZoomFactor.Height := 14;
    lblZoomFactor.Caption := '1.0';
    lblZoomFactor.ElementClassName := 'label';
    centralPanel.SetParentComponent(Self);
    centralPanel.Name := 'centralPanel';
    centralPanel.Left := 0;
    centralPanel.Top := 0;
    centralPanel.Width := 1392;
    centralPanel.Height := 822;
    centralPanel.ElementClassName := 'card';
    centralPanel.Align := alClient;
    centralPanel.BorderColor := clSilver;
    centralPanel.BorderStyle := bsNone;
    centralPanel.ChildOrder := 4;
    centralPanel.Color := clWindow;
    centralPanel.ElementBodyClassName := 'card border-0';
    lb.SetParentComponent(centralPanel);
    lb.Name := 'lb';
    lb.Left := 1264;
    lb.Top := 0;
    lb.Width := 128;
    lb.Height := 822;
    lb.Align := alRight;
    lb.AutoSize := False;
    lb.BorderStyle := bsNone;
    lb.SelLength := 0;
    lb.SelStart := 0;
    pnlScroll.SetParentComponent(centralPanel);
    pnlScroll.Name := 'pnlScroll';
    pnlScroll.Left := 1110;
    pnlScroll.Top := 0;
    pnlScroll.Width := 154;
    pnlScroll.Height := 822;
    pnlScroll.ElementClassName := 'card border-0';
    pnlScroll.Align := alRight;
    pnlScroll.BorderColor := clSilver;
    pnlScroll.BorderStyle := bsNone;
    pnlScroll.Caption := '';
    pnlScroll.ChildOrder := 3;
    pnlScroll.ElementBodyClassName := 'card-body pl-10 bg-dark text-white';
    WebPanel1.SetParentComponent(centralPanel);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1110;
    WebPanel1.Height := 822;
    WebPanel1.ElementClassName := 'card border-0';
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.Caption := ',';
    WebPanel1.ChildOrder := 4;
    WebPanel1.ElementBodyClassName := 'card-body pl-10 bg-dark text-white';
    scrollBarVert.SetParentComponent(WebPanel1);
    scrollBarVert.Name := 'scrollBarVert';
    scrollBarVert.Left := 1092;
    scrollBarVert.Top := 0;
    scrollBarVert.Width := 18;
    scrollBarVert.Height := 822;
    scrollBarVert.Align := alRight;
    scrollBarVert.ParentDoubleBuffered := False;
    scrollBarVert.DoubleBuffered := True;
    scrollBarVert.TabOrder := 0;
    scrollBarVert.Appearance.ThumbFill.Color := 14803425;
    scrollBarVert.Appearance.ScrollButtonLeftFill.Color := 14803425;
    scrollBarVert.Appearance.ScrollButtonRightFill.Color := 14803425;
    scrollBarVert.Appearance.ScrollButtonLeftHoverFill.Color := 16511461;
    scrollBarVert.Appearance.ScrollButtonRightHoverFill.Color := 16511461;
    scrollBarVert.Appearance.ScrollButtonLeftDownFill.Color := 16378843;
    scrollBarVert.Appearance.ScrollButtonRightDownFill.Color := 16378843;
    scrollBarVert.Appearance.ThumbButtonLeftFill.Color := 14803425;
    scrollBarVert.Appearance.ThumbButtonRightFill.Color := 14803425;
    scrollBarVert.Appearance.ThumbButtonLeftHoverFill.Color := 16511461;
    scrollBarVert.Appearance.ThumbButtonRightHoverFill.Color := 16511461;
    scrollBarVert.Appearance.ThumbButtonLeftDownFill.Color := 16378843;
    scrollBarVert.Appearance.ThumbButtonRightDownFill.Color := 16378843;
    scrollBarVert.Appearance.ThumbStroke.Color := 8882055;
    scrollBarVert.Appearance.ScrollButtonLeftStroke.Color := 8882055;
    scrollBarVert.Appearance.ScrollButtonRightStroke.Color := 8882055;
    scrollBarVert.Appearance.ScrollButtonLeftHoverStroke.Color := 11632444;
    scrollBarVert.Appearance.ScrollButtonRightHoverStroke.Color := 11632444;
    scrollBarVert.Appearance.ScrollButtonLeftDownStroke.Color := 11632444;
    scrollBarVert.Appearance.ScrollButtonRightDownStroke.Color := 11632444;
    scrollBarVert.Appearance.ThumbButtonLeftStroke.Color := 8882055;
    scrollBarVert.Appearance.ThumbButtonRightStroke.Color := 8882055;
    scrollBarVert.Appearance.ThumbButtonLeftHoverStroke.Color := 11632444;
    scrollBarVert.Appearance.ThumbButtonRightHoverStroke.Color := 11632444;
    scrollBarVert.Appearance.ThumbButtonLeftDownStroke.Color := 11632444;
    scrollBarVert.Appearance.ThumbButtonRightDownStroke.Color := 11632444;
    scrollBarVert.Appearance.FixedThumb := True;
    scrollBarVert.LargeChange := 1.000000000000000000;
    scrollBarVert.Max := 1000.000000000000000000;
    scrollBarVert.PageSize := 10.000000000000000000;
    SetEvent(scrollBarVert, Self, 'OnValueChanged', 'scrollBarVertValueChanged');
    pnlLeft.SetParentComponent(WebPanel1);
    pnlLeft.Name := 'pnlLeft';
    pnlLeft.Left := 0;
    pnlLeft.Top := 0;
    pnlLeft.Width := 140;
    pnlLeft.Height := 822;
    pnlLeft.ElementClassName := 'card border-0';
    pnlLeft.Align := alLeft;
    pnlLeft.BorderColor := clWhite;
    pnlLeft.BorderStyle := bsNone;
    pnlLeft.Caption := '';
    pnlLeft.ChildOrder := 3;
    pnlLeft.Color := clWhite;
    pnlLeft.ElementBodyClassName := 'card-body pl-10 bg-dark text-white';
    WebLabel2.SetParentComponent(pnlLeft);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 519;
    WebLabel2.Width := 12;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Fill';
    WebLabel2.ElementClassName := 'label';
    WebLabel1.SetParentComponent(pnlLeft);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 14;
    WebLabel1.Top := 453;
    WebLabel1.Width := 33;
    WebLabel1.Height := 14;
    WebLabel1.Caption := 'Outline';
    WebLabel1.ElementClassName := 'label';
    btnNodeFillColor.SetParentComponent(pnlLeft);
    btnNodeFillColor.Name := 'btnNodeFillColor';
    btnNodeFillColor.Left := 16;
    btnNodeFillColor.Top := 539;
    btnNodeFillColor.Width := 88;
    btnNodeFillColor.Height := 34;
    btnNodeFillColor.ElementClassName := 'card border-0';
    btnNodeFillColor.ChildOrder := 3;
    btnNodeFillColor.Color := clBlack;
    btnNodeFillColor.Role := '';
    SetEvent(btnNodeFillColor, Self, 'OnSelect', 'btnNodeFillColorSelect');
    btnNodeOutlineColor.SetParentComponent(pnlLeft);
    btnNodeOutlineColor.Name := 'btnNodeOutlineColor';
    btnNodeOutlineColor.Left := 14;
    btnNodeOutlineColor.Top := 473;
    btnNodeOutlineColor.Width := 73;
    btnNodeOutlineColor.Height := 35;
    btnNodeOutlineColor.ElementClassName := 'card';
    btnNodeOutlineColor.ChildOrder := 3;
    btnNodeOutlineColor.Color := clBlack;
    btnNodeOutlineColor.Role := '';
    SetEvent(btnNodeOutlineColor, Self, 'OnSelect', 'btnNodeOutlineColorClick');
    trackBarZoom.SetParentComponent(pnlLeft);
    trackBarZoom.Name := 'trackBarZoom';
    trackBarZoom.Left := 27;
    trackBarZoom.Top := 607;
    trackBarZoom.Width := 20;
    trackBarZoom.Height := 141;
    trackBarZoom.ElementClassName := 'range-field my-4 w-25';
    trackBarZoom.ChildOrder := 6;
    trackBarZoom.Max := 100;
    trackBarZoom.Min := 0;
    trackBarZoom.Orientation := trVertical;
    trackBarZoom.Position := 0;
    trackBarZoom.Role := '';
    SetEvent(trackBarZoom, Self, 'OnChange', 'trackBarZoomChange');
    btnNew.SetParentComponent(pnlLeft);
    btnNew.Name := 'btnNew';
    btnNew.Left := 14;
    btnNew.Top := 12;
    btnNew.Width := 90;
    btnNew.Height := 25;
    btnNew.Caption := 'New';
    btnNew.ChildOrder := 15;
    btnNew.ElementClassName := 'btn btn-primary';
    btnNew.ElementFont := efCSS;
    btnNew.HeightStyle := ssAuto;
    SetEvent(btnNew, Self, 'OnClick', 'btnClearClick');
    btnSave.SetParentComponent(pnlLeft);
    btnSave.Name := 'btnSave';
    btnSave.Left := 14;
    btnSave.Top := 63;
    btnSave.Width := 90;
    btnSave.Height := 25;
    btnSave.Caption := 'Save';
    btnSave.ChildOrder := 15;
    btnSave.ElementClassName := 'btn btn-primary';
    btnSave.ElementFont := efCSS;
    btnSave.HeightStyle := ssAuto;
    SetEvent(btnSave, Self, 'OnClick', 'mnuSaveClick');
    WebHTMLDiv1.SetParentComponent(pnlLeft);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 14;
    WebHTMLDiv1.Top := 115;
    WebHTMLDiv1.Width := 90;
    WebHTMLDiv1.Height := 44;
    WebHTMLDiv1.ElementClassName := 'upload-btn-wrapper';
    WebHTMLDiv1.ChildOrder := 7;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    filePicker.SetParentComponent(WebHTMLDiv1);
    filePicker.Name := 'filePicker';
    filePicker.Left := 0;
    filePicker.Top := 0;
    filePicker.Width := 96;
    filePicker.Height := 45;
    filePicker.ElementClassName := 'upload-cover';
    filePicker.ChildOrder := 5;
    filePicker.ElementFont := efCSS;
    SetEvent(filePicker, Self, 'OnChange', 'filePickerChange');
    SetEvent(filePicker, Self, 'OnGetFileAsText', 'filePickerGetFileAsText');
    btnFilePicker.SetParentComponent(WebHTMLDiv1);
    btnFilePicker.Name := 'btnFilePicker';
    btnFilePicker.Left := 0;
    btnFilePicker.Top := 0;
    btnFilePicker.Width := 90;
    btnFilePicker.Height := 45;
    btnFilePicker.Caption := 'Load';
    btnFilePicker.ChildOrder := 7;
    btnFilePicker.ElementClassName := 'btnOverlay';
    btnFilePicker.ElementFont := efCSS;
    btnFilePicker.HeightStyle := ssAuto;
    btnIdle.SetParentComponent(pnlLeft);
    btnIdle.Name := 'btnIdle';
    btnIdle.Left := 14;
    btnIdle.Top := 248;
    btnIdle.Width := 90;
    btnIdle.Height := 36;
    btnIdle.ElementClassName := 'btn btn-primary';
    btnIdle.Glyph.LoadFromFile('ufMain.pnlLeft.btnIdle.Glyph.png');
    SetEvent(btnIdle, Self, 'OnClick', 'btnAddNodeXClick');
    btnUniUni.SetParentComponent(pnlLeft);
    btnUniUni.Name := 'btnUniUni';
    btnUniUni.Left := 14;
    btnUniUni.Top := 286;
    btnUniUni.Width := 90;
    btnUniUni.Height := 36;
    btnUniUni.ElementClassName := 'btn btn-primary';
    btnUniUni.Glyph.LoadFromFile('ufMain.pnlLeft.btnUniUni.Glyph.png');
    SetEvent(btnUniUni, Self, 'OnClick', 'btnAddUniUniReactionClick');
    btnUniBi.SetParentComponent(pnlLeft);
    btnUniBi.Name := 'btnUniBi';
    btnUniBi.Left := 14;
    btnUniBi.Top := 358;
    btnUniBi.Width := 90;
    btnUniBi.Height := 32;
    btnUniBi.ElementClassName := 'btn btn-primary';
    btnUniBi.Glyph.LoadFromFile('ufMain.pnlLeft.btnUniBi.Glyph.png');
    SetEvent(btnUniBi, Self, 'OnClick', 'addUniBiReactionClick');
    btnBiUni.SetParentComponent(pnlLeft);
    btnBiUni.Name := 'btnBiUni';
    btnBiUni.Left := 14;
    btnBiUni.Top := 324;
    btnBiUni.Width := 90;
    btnBiUni.Height := 32;
    btnBiUni.ElementClassName := 'btn btn-primary';
    btnBiUni.Glyph.LoadFromFile('ufMain.pnlLeft.btnBiUni.Glyph.png');
    SetEvent(btnBiUni, Self, 'OnClick', 'btnAddBiUniClick');
    btnBiBi.SetParentComponent(pnlLeft);
    btnBiBi.Name := 'btnBiBi';
    btnBiBi.Left := 14;
    btnBiBi.Top := 392;
    btnBiBi.Width := 90;
    btnBiBi.Height := 32;
    btnBiBi.ElementClassName := 'btn btn-primary';
    btnBiBi.Glyph.LoadFromFile('ufMain.pnlLeft.btnBiBi.Glyph.png');
    SetEvent(btnBiBi, Self, 'OnClick', 'btnAddBiBiClick');
    WebSpeedButton1.SetParentComponent(pnlLeft);
    WebSpeedButton1.Name := 'WebSpeedButton1';
    WebSpeedButton1.Left := 14;
    WebSpeedButton1.Top := 194;
    WebSpeedButton1.Width := 90;
    WebSpeedButton1.Height := 36;
    WebSpeedButton1.ElementClassName := 'btn btn-primary';
    WebSpeedButton1.Glyph.LoadFromFile('ufMain.pnlLeft.WebSpeedButton1.Glyph.png');
    SetEvent(WebSpeedButton1, Self, 'OnClick', 'btnIdleClick');
    pnlInner.SetParentComponent(WebPanel1);
    pnlInner.Name := 'pnlInner';
    pnlInner.Left := 140;
    pnlInner.Top := 0;
    pnlInner.Width := 952;
    pnlInner.Height := 822;
    pnlInner.ElementClassName := 'card border-0';
    pnlInner.Align := alClient;
    pnlInner.BorderColor := clWhite;
    pnlInner.BorderStyle := bsNone;
    pnlInner.ChildOrder := 3;
    pnlInner.ElementBodyClassName := 'card-body';
    scrollBarHoriz.SetParentComponent(pnlInner);
    scrollBarHoriz.Name := 'scrollBarHoriz';
    scrollBarHoriz.Left := 0;
    scrollBarHoriz.Top := 804;
    scrollBarHoriz.Width := 952;
    scrollBarHoriz.Height := 18;
    scrollBarHoriz.Align := alBottom;
    scrollBarHoriz.ParentDoubleBuffered := False;
    scrollBarHoriz.Color := 15592941;
    scrollBarHoriz.DoubleBuffered := True;
    scrollBarHoriz.TabOrder := 0;
    scrollBarHoriz.Fill.Color := 15592941;
    scrollBarHoriz.Stroke.Color := 8882055;
    scrollBarHoriz.Appearance.ThumbFill.Color := 14803425;
    scrollBarHoriz.Appearance.ScrollButtonLeftFill.Color := 14803425;
    scrollBarHoriz.Appearance.ScrollButtonRightFill.Color := 14803425;
    scrollBarHoriz.Appearance.ScrollButtonLeftHoverFill.Color := 16511461;
    scrollBarHoriz.Appearance.ScrollButtonRightHoverFill.Color := 16511461;
    scrollBarHoriz.Appearance.ScrollButtonLeftDownFill.Color := 16378843;
    scrollBarHoriz.Appearance.ScrollButtonRightDownFill.Color := 16378843;
    scrollBarHoriz.Appearance.ThumbButtonLeftFill.Color := 14803425;
    scrollBarHoriz.Appearance.ThumbButtonRightFill.Color := 14803425;
    scrollBarHoriz.Appearance.ThumbButtonLeftHoverFill.Color := 16511461;
    scrollBarHoriz.Appearance.ThumbButtonRightHoverFill.Color := 16511461;
    scrollBarHoriz.Appearance.ThumbButtonLeftDownFill.Color := 16378843;
    scrollBarHoriz.Appearance.ThumbButtonRightDownFill.Color := 16378843;
    scrollBarHoriz.Appearance.ThumbStroke.Color := 8882055;
    scrollBarHoriz.Appearance.ScrollButtonLeftStroke.Color := 8882055;
    scrollBarHoriz.Appearance.ScrollButtonRightStroke.Color := 8882055;
    scrollBarHoriz.Appearance.ScrollButtonLeftHoverStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ScrollButtonRightHoverStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ScrollButtonLeftDownStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ScrollButtonRightDownStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ThumbButtonLeftStroke.Color := 8882055;
    scrollBarHoriz.Appearance.ThumbButtonRightStroke.Color := 8882055;
    scrollBarHoriz.Appearance.ThumbButtonLeftHoverStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ThumbButtonRightHoverStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ThumbButtonLeftDownStroke.Color := 11632444;
    scrollBarHoriz.Appearance.ThumbButtonRightDownStroke.Color := 11632444;
    scrollBarHoriz.Appearance.FixedThumb := True;
    scrollBarHoriz.Kind := sbkHorizontal;
    scrollBarHoriz.Max := 1000.000000000000000000;
    scrollBarHoriz.PageSize := 10.000000000000000000;
    SetEvent(scrollBarHoriz, Self, 'OnValueChanged', 'scrollbarHorizValueChanged');
    paintBox.SetParentComponent(pnlInner);
    paintBox.Name := 'paintBox';
    paintBox.Left := 0;
    paintBox.Top := 0;
    paintBox.Width := 952;
    paintBox.Height := 804;
    paintBox.ElementClassName := 'panel border-0';
    paintBox.Align := alClient;
    paintBox.ChildOrder := 2;
    SetEvent(paintBox, Self, 'OnPaint', 'paintBoxPaint');
    SetEvent(paintBox, Self, 'OnKeyDown', 'paintBoxKeyDown');
    SetEvent(paintBox, Self, 'OnMouseDown', 'paintBoxMouseDown');
    SetEvent(paintBox, Self, 'OnMouseMove', 'paintBoxMouseMove');
    SetEvent(paintBox, Self, 'OnMouseUp', 'paintBoxMouseUp');
    SetEvent(paintBox, Self, 'OnMouseWheel', 'paintBoxMouseWheel');
  finally
    pnlBottom.AfterLoadDFMValues;
    btnDraw.AfterLoadDFMValues;
    btnRandomNetwork.AfterLoadDFMValues;
    btnAutoLayout.AfterLoadDFMValues;
    btnCenter.AfterLoadDFMValues;
    btnAbout.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    lblX.AfterLoadDFMValues;
    lblY.AfterLoadDFMValues;
    lblZoomFactor1.AfterLoadDFMValues;
    lblZoomFactor.AfterLoadDFMValues;
    centralPanel.AfterLoadDFMValues;
    lb.AfterLoadDFMValues;
    pnlScroll.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    scrollBarVert.AfterLoadDFMValues;
    pnlLeft.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    btnNodeFillColor.AfterLoadDFMValues;
    btnNodeOutlineColor.AfterLoadDFMValues;
    trackBarZoom.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    filePicker.AfterLoadDFMValues;
    btnFilePicker.AfterLoadDFMValues;
    btnIdle.AfterLoadDFMValues;
    btnUniUni.AfterLoadDFMValues;
    btnUniBi.AfterLoadDFMValues;
    btnBiUni.AfterLoadDFMValues;
    btnBiBi.AfterLoadDFMValues;
    WebSpeedButton1.AfterLoadDFMValues;
    pnlInner.AfterLoadDFMValues;
    scrollBarHoriz.AfterLoadDFMValues;
    paintBox.AfterLoadDFMValues;
  end;
end;

end.
