{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.DesignIntf;

interface

uses
  Classes, Web, JS, TypInfo, WEBLib.Forms;

type
  TLoadJavaScriptCallBack = procedure(AJavaScriptFile: String) of object;
  TErrorJavaScriptCallBack = procedure(AError: TJSErrorEvent) of object;

  TComponentClass = class of TComponent;

  TIconArray = array of string;

  TComponentClassArray = array of TComponentClass;

  TRegisteredComponent = class
  private
    FClass: TComponentClass;
    FIcon: string;
    FPage: string;
  public
    constructor Create(APage: string; AClass: TComponentClass; AIcon: string = '');
    property &Class: TComponentClass read FClass write FClass;
    property Page: string read FPage write FPage;
    property Icon: string read FIcon write FIcon;
  end;

  IDesignerSelections = interface
    function Add(const Item: TPersistent): Integer;
    function Get(Index: Integer): TPersistent;
    function GetCount: Integer;
    property Count: Integer read GetCount;
    property Items[Index: Integer]: TPersistent read Get; default;
  end;

  TGetStrProc = procedure(AUnitName: string);

  TSelectionEditor = class(TObject)
  public
    procedure RequiresUnits(Proc: TGetStrProc); virtual;
  end;

  TSelectionEditorClass = class of TSelectionEditor;

  TRegisteredSelectionEditor = class
  private
    FClass: TComponentClass;
    FEditor: TSelectionEditorClass;
  public
    constructor Create(AClass: TComponentClass; AEditorClass: TSelectionEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property Editor: TSelectionEditorClass read FEditor write FEditor;
  end;

  TLibraryRequirementsEditor = class
  public
    procedure RequiresLibraries(Proc: TGetStrProc); virtual;
  end;

  TLibraryRequirementsEditorClass = class of TLibraryRequirementsEditor;

  TRegisteredLibraryRequirementsEditor = class
  private
    FClass: TComponentClass;
    FEditor: TLibraryRequirementsEditorClass;
  public
    constructor Create(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property Editor: TLibraryRequirementsEditorClass read FEditor write FEditor;
  end;

  TPropertyAttribute = (paDialog, paValueList, paSortList, paValueEditable);

  TPropertyAttributes = set of TPropertyAttribute;

  TPropertyEditor = class(TObject)
  private
    FComponent: TComponent;
    FPropertyName: string;
    FPropertyInfo: TTypeMemberProperty;
    FResultProc: TModalResultProc;
  protected
    property ResultProc: TModalResultProc read FResultProc;
  public
    constructor Create;
    property Component: TComponent read FComponent write FComponent;
    property PropertyName: string read FPropertyName write FPropertyName;
    property PropertyInfo: TTypeMemberProperty read FPropertyInfo write FPropertyInfo;
    procedure Edit; virtual; overload;
    procedure Edit(AProc: TModalResultProc); virtual; overload;
    function GetAttributes: TPropertyAttributes; virtual;
    procedure GetValues(Proc: TGetStrProc); virtual;
    procedure SetValue(const Value: string); virtual;
  end;

  TPropertyEditorClass = class of TPropertyEditor;

  TRegisteredPropertyEditor = class
  private
    FClass: TComponentClass;
    FPropertyName: string;
    FPropertyType: PTypeInfo;
    FEditor: TPropertyEditorClass;
  public
    constructor Create(APropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string; AEditor: TPropertyEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property PropertyType: PTypeInfo read FPropertyType write FPropertyType;
    property PropertyName: string read FPropertyName write FPropertyName;
    property Editor: TPropertyEditorClass read FEditor write FEditor;
  end;

  TRegisteredIgnoredProperty = class
  private
    FClass: TComponentClass;
    FPropertyName: string;
  public
    constructor Create(AClass: TComponentClass; AProperty: string);
    property &Class: TComponentClass read FClass write FClass;
    property PropertyName: string read FPropertyName write FPropertyName;
  end;

  TComponentEditor = class
  private
    FComponent: TComponent;
    FResultProc: TModalResultProc;
    FExecResultProc: TModalResultProc;
  protected
    property ResultProc: TModalResultProc read FResultProc;
    property ExecResultProc: TModalResultProc read FExecResultProc;
  public
    property Component: TComponent read FComponent write FComponent;
    procedure Edit; virtual; overload;
    procedure Edit(AProc: TModalResultProc); virtual; overload;
    procedure ExecuteVerb(Index: Integer); virtual; overload;
    procedure ExecuteVerb(Index: Integer; AProc: TModalResultProc); virtual; overload;
    function GetVerb(Index: Integer): string; virtual;
    function GetVerbCount: Integer; virtual;
    function GetComponent: TComponent; virtual;
    function GetDefaultProperty: string; virtual;
  end;

  TComponentEditorClass = class of TComponentEditor;

  TRegisteredComponentEditor = class
  private
    FClass: TComponentClass;
    FEditor: TComponentEditorClass;
  public
    constructor Create(AClass: TComponentClass; AEditorClass: TComponentEditorClass);
    property &Class: TComponentClass read FClass write FClass;
    property Editor: TComponentEditorClass read FEditor write FEditor;
  end;


procedure RegisterComponents(Page: string; ComponentClasses: TComponentClassArray; Icons: TIconArray = nil);
procedure RegisterSelectionEditor(AClass: TComponentClass; AEditorClass: TSelectionEditorClass);
procedure RegisterPropertyEditor(PropertyType: PTypeInfo; ComponentClass: TClass; const PropertyName: string; EditorClass: TPropertyEditorClass);
procedure RegisterComponentEditor(AClass: TComponentClass; ComponentEditor: TComponentEditorClass);
procedure RegisterIgnoredProperty(ComponentClass: TClass; const PropertyName: string);
procedure RegisterLibraryRequirementsEditor(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);

procedure GetRegisteredComponents(List: TList);
procedure GetRegisteredPages(List: TList);
procedure GetSelectionEditors(List: TList);
procedure LoadComponentFromJavaScript(JavaScriptFile: string; CallBack: TLoadJavaScriptCallBack; ErrorCallBack: TErrorJavaScriptCallBack = nil);
procedure InitializeComponentFromJavaScript(JavaScriptFile: string);
function GetRegisteredComponentClass(ComponentClassName: string): TComponentClass;
function GetRegisteredComponent(ComponentClassName: string): TRegisteredComponent;
function GetRegisteredSelectionEditor(ComponentClassName: string): TRegisteredSelectionEditor;
function GetRegisteredLibraryRequirementsEditor(ComponentClassName: string): TRegisteredLibraryRequirementsEditor;
function GetRegisteredPropertyEditor(PropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string): TRegisteredPropertyEditor;
function GetRegisteredComponentEditor(AClass: TComponentClass): TRegisteredComponentEditor;
function IsComponentFromJavaScriptLoaded(JavaScriptFile: string): Boolean;
function IsIgnoredProperty(AClass: TComponentClass; AProperty: string): boolean;

implementation

uses
  SysUtils;

var
  RegisteredComponentList: TJSObject;
  RegisteredSelectionEditorList: TJSObject;
  RegisteredPropertyEditorList: TJSObject;
  RegisteredIgnoredPropertyList: TJSObject;
  RegisteredLibraryRequirementsEditorList: TJSObject;
  RegisteredComponentEditorList: TJSObject;

procedure RegisterComponents(Page: string; ComponentClasses: TComponentClassArray; Icons: TIconArray = nil);
var
  I: Integer;
  c: TComponentClass;
  ic: String;
begin
  for I := 0 to Length(ComponentClasses) - 1 do
  begin
    c := ComponentClasses[I];
    if Assigned(Icons) then
      ic := Icons[I]
    else
      ic := '';

    RegisteredComponentList[c.ClassName] := TRegisteredComponent.Create(Page, c, ic);
  end;
end;

function GetRegisteredComponent(ComponentClassName: string): TRegisteredComponent;
var
  res: TRegisteredComponent;
begin
  Result := nil;
  if ComponentClassName = '' then
    Exit;

  res := nil;

  // case insensitive class search
  asm
    var a = Object.keys($impl.RegisteredComponentList).map(function(key) {
      return [$impl.RegisteredComponentList[key]];
    });

    for (let el of a) {
      if (el[0].FClass.$classname.toLowerCase() == ComponentClassName.toLowerCase()) {
        res = el[0];
        break; }
    }
  end;

  Result := res;

//  Result := TRegisteredComponent(RegisteredComponentList[ComponentClassName]);
end;

function GetRegisteredComponentClass(ComponentClassName: string): TComponentClass;
var
  r: TRegisteredComponent;
begin
  Result := nil;
  r := GetRegisteredComponent(ComponentClassName);
  if Assigned(r) then
    Result := r.&Class;
end;

procedure GetRegisteredPages(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredComponentList).map(function(key) {
      return [$impl.RegisteredComponentList[key]];
    });

    a.forEach(function(element) {
      var p = element[0].FPage;
      if (List.IndexOf(p) == -1) {
        List.Add(element[0].FPage);
      }
    });
  end;
end;

procedure GetRegisteredComponents(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredComponentList).map(function(key) {
      return [$impl.RegisteredComponentList[key]];
    });

    a.forEach(function(element) {
      List.Add(element[0]);
    });
  end;
end;

procedure RegisterSelectionEditor(AClass: TComponentClass; AEditorClass: TSelectionEditorClass);
begin
  RegisteredSelectionEditorList[AClass.ClassName] := TRegisteredSelectionEditor.Create(AClass, AEditorClass);
end;


procedure GetSelectionEditors(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredSelectionEditorList).map(function(key) {
      return [$impl.RegisteredSelectionEditorList[key]];
    });

    a.forEach(function(element) {
      List.Add(element[0]);
    });
  end;
end;

function GetRegisteredSelectionEditor(ComponentClassName: string): TRegisteredSelectionEditor;
begin
  Result := nil;
  if ComponentClassName = '' then
    Exit;

  Result := TRegisteredSelectionEditor(RegisteredSelectionEditorList[ComponentClassName]);
end;

function GetRegisteredComponentEditor(AClass: TComponentClass): TRegisteredComponentEditor;
begin
  Result := TRegisteredComponentEditor(RegisteredComponentEditorList[AClass.ClassName]);
end;


procedure RegisterLibraryRequirementsEditor(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
begin
  RegisteredLibraryRequirementsEditorList[AClass.ClassName] := TRegisteredLibraryRequirementsEditor.Create(AClass, AEditorClass);
end;

function GetRegisteredLibraryRequirementsEditor(ComponentClassName: string): TRegisteredLibraryRequirementsEditor;
begin
  Result := nil;
  if ComponentClassName = '' then
    Exit;

  Result := TRegisteredLibraryRequirementsEditor(RegisteredLibraryRequirementsEditorList[ComponentClassName]);
end;

procedure GetPropertyEditors(List: TList);
begin
  asm
    var a = Object.keys($impl.RegisteredPropertyEditorList).map(function(key) {
      return [$impl.RegisteredPropertyEditorList[key]];
    });

    a.forEach(function(element) {
      List.Add(element[0]);
    });
  end;
end;

procedure RegisterPropertyEditor(PropertyType: PTypeInfo; ComponentClass: TClass; const PropertyName: string; EditorClass: TPropertyEditorClass);
var
  LKey, LType, LClass: string;
begin
  LType := '';
  if Assigned(PropertyType) then
    LType := TTypeInfo(PropertyType).Name;

  LClass := '';
  if Assigned(ComponentClass) then
    LClass := ComponentClass.ClassName;

  LKey := LType + '_' + LClass + '_' + PropertyName;
  RegisteredPropertyEditorList[LKey] := TRegisteredPropertyEditor.Create(PropertyType, TComponentClass(ComponentClass), PropertyName, EditorClass);
end;

function GetRegisteredPropertyEditor(PropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string): TRegisteredPropertyEditor;
var
  LKey, LType: string;
  LPropEditor: TRegisteredPropertyEditor;
  LList: TList;
  i: integer;
begin
  Result := nil;

  LType := TTypeInfo(PropertyType).Name;

  LKey := LType + '__';
  LPropEditor := TRegisteredPropertyEditor(RegisteredPropertyEditorList[LKey]);
  if Assigned(LPropEditor) then
  begin
    Result := LPropEditor;
  end
  else
  if Assigned(AClass) and (AProperty <> '') then
  begin
    LKey := LType + '_' + AClass.ClassName + '_' + AProperty;
    LPropEditor := TRegisteredPropertyEditor(RegisteredPropertyEditorList[LKey]);
    if Assigned(LPropEditor) then
    begin
      Result := LPropEditor;
    end;
  end
  else
  begin
    LList  := TList.Create;
    GetPropertyEditors(LList);

    for i := 0 to LList.Count - 1 do
    begin
      LPropEditor := TRegisteredPropertyEditor(LList.Items[i]);

      if (LPropEditor.PropertyType = PropertyType) then
      begin
        if (LPropEditor.PropertyName = '') or (LPropEditor.PropertyName = AProperty) then
        begin
          if not Assigned(LPropEditor.&Class) or (Assigned(AClass) and (LPropEditor.&Class.ClassName = AClass.ClassName)) then
          begin
            Result := LPropEditor;
            Break;
          end;
        end;
      end;
    end;
    LList.Free;
  end;
end;

procedure RegisterComponentEditor(AClass: TComponentClass; ComponentEditor: TComponentEditorClass);
begin
  RegisteredComponentEditorList[AClass.ClassName] := TRegisteredComponentEditor.Create(AClass, ComponentEditor);
end;


constructor TRegisteredIgnoredProperty.Create(AClass: TComponentClass; AProperty: string);
begin
  inherited Create;

  FClass := AClass;
  FPropertyName := AProperty;
end;

procedure RegisterIgnoredProperty(ComponentClass: TClass; const PropertyName: string);
var
  LKey, LClass: string;
begin
  LClass := '';
  if Assigned(ComponentClass) then
    LClass := ComponentClass.ClassName;

  LKey := LClass + '_' + PropertyName;
  RegisteredIgnoredPropertyList[LKey] := TRegisteredIgnoredProperty.Create(TComponentClass(ComponentClass), PropertyName);
end;

function IsIgnoredProperty(AClass: TComponentClass; AProperty: string): boolean;
var
  LKey: string;
  LIgnoredProperty: TRegisteredIgnoredProperty;

begin
  Result := false;

  if not Assigned(AClass) then
  begin
    LKey := '_' + AProperty;
    LIgnoredProperty := TRegisteredIgnoredProperty(RegisteredIgnoredPropertyList[LKey]);
    if Assigned(LIgnoredProperty) then
    begin
      Result := true;
    end;
  end
  else
  if Assigned(AClass) and (AProperty <> '') then
  begin
    LKey := AClass.ClassName + '_' + AProperty;
    LIgnoredProperty := TRegisteredIgnoredProperty(RegisteredIgnoredPropertyList[LKey]);
    if Assigned(LIgnoredProperty) then
    begin
      Result := true;
    end
    else
    begin
      LKey := '_' + AProperty;
      LIgnoredProperty := TRegisteredIgnoredProperty(RegisteredIgnoredPropertyList[LKey]);
      if Assigned(LIgnoredProperty) then
      begin
        Result := true;
      end;
    end;
  end
end;


{$HINTS OFF}
procedure InitializeComponentFromJavaScript(JavaScriptFile: string);
var
  src: String;
begin
  if IsComponentFromJavaScriptLoaded(JavaScriptFile) then
  begin
    src := JavaScriptFile;
    asm
      var n = src.substr(0, src.lastIndexOf("."))
      var module = pas[n];
      rtl.loadintf(module);
      rtl.loadimpl(module);
    end;
  end;
end;

function IsComponentFromJavaScriptLoaded(JavaScriptFile: string): Boolean;
var
  src: String;
begin
  src := JavaScriptFile;
  asm
    var n = src.substr(0, src.lastIndexOf("."))
    return (pas[n]);
  end;
end;
{$HINTS ON}

procedure LoadComponentFromJavaScript(JavaScriptFile: string; CallBack: TLoadJavaScriptCallBack; ErrorCallBack: TErrorJavaScriptCallBack = nil);
begin
  asm
    var self = this;
    var JavaScript = {
      loadcomponent: function(src) {
        var n = src.substr(0, src.lastIndexOf("."));
        if (!pas[n]) {
          var script = document.createElement('script'), loaded;
          script.setAttribute('src', src);
          script.onerror = function(e){
            if (ErrorCallBack != null)
            {
              ErrorCallBack(e);
            }
          }
          script.onreadystatechange = script.onload = function() {
            if (!loaded) {
              if (CallBack != null)
                CallBack(src);
            }
            loaded = true;
          };
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    };

    JavaScript.loadcomponent(JavaScriptFile);
  end;
end;

{ TRegisteredComponent }

constructor TRegisteredComponent.Create(APage: string; AClass: TComponentClass; AIcon: string = '');
begin
  FPage := APage;
  FClass := AClass;
  FIcon := AIcon;
end;

{ TSelectionEditor }

procedure TSelectionEditor.RequiresUnits(Proc: TGetStrProc);
begin
  //
end;

{ TRegisteredSelectionEditor }

constructor TRegisteredSelectionEditor.Create(AClass: TComponentClass;
  AEditorClass: TSelectionEditorClass);
begin
  inherited Create;
  FClass := AClass;
  FEditor := AEditorClass;
end;

{ TRegisteredComponentEditor }

constructor TRegisteredComponentEditor.Create(AClass: TComponentClass;
  AEditorClass: TComponentEditorClass);
begin
  inherited Create;
  FClass := AClass;
  FEditor := AEditorClass;
end;


{ TRegisteredLibraryRequirementsEditor }

constructor TRegisteredLibraryRequirementsEditor.Create(AClass: TComponentClass; AEditorClass: TLibraryRequirementsEditorClass);
begin
  inherited Create;
  FClass := AClass;
  FEditor := AEditorClass;
end;

{ TPropertyEditor }

constructor TPropertyEditor.Create;
begin
  FPropertyName := '';
  FPropertyInfo := nil;
end;

procedure TPropertyEditor.Edit;
begin
  Edit(nil);
end;

procedure TPropertyEditor.Edit(AProc: TModalResultProc);
begin
  FResultProc := AProc;
end;

function TPropertyEditor.GetAttributes: TPropertyAttributes;
begin
  Result := [paDialog];
end;

procedure TPropertyEditor.GetValues(Proc: TGetStrProc);
begin
  //
end;

procedure TPropertyEditor.SetValue(const Value: string);
var
  tmp: TTypeMemberProperty;

begin
  if Assigned(Component) then
  begin
    // find the Cursor property
    tmp := FindPropInfo(Component, PropertyName);
    if Assigned(tmp) then
    begin
      SetStrProp(Component, PropertyName, Value);
    end;
  end;
end;

{ TComponentEditor }

procedure TComponentEditor.Edit;
begin
  Edit(nil);
end;

procedure TComponentEditor.Edit(AProc: TModalResultProc);
begin
  FResultProc := AProc;
end;

procedure TComponentEditor.ExecuteVerb(Index: Integer);
begin
  ExecuteVerb(Index, nil);
end;

procedure TComponentEditor.ExecuteVerb(Index: Integer; AProc: TModalResultProc);
begin
  FExecResultProc := AProc;
end;

function TComponentEditor.GetVerb(Index: Integer): string;
begin
  Result := '';
end;

function TComponentEditor.GetVerbCount: Integer;
begin
  Result := 0;
end;

function TComponentEditor.GetComponent: TComponent;
begin
  Result := nil;
end;

function TComponentEditor.GetDefaultProperty: string;
begin
  Result := '';
end;

{ TRegisteredPropertyEditor }

constructor TRegisteredPropertyEditor.Create(APropertyType: PTypeInfo; AClass: TComponentClass; AProperty: string; AEditor: TPropertyEditorClass);
begin
  inherited Create;

  FClass := AClass;
  FPropertyName := AProperty;
  FPropertyType := APropertyType;
  FEditor := AEditor;
end;


procedure TLibraryRequirementsEditor.RequiresLibraries(Proc: TGetStrProc);
begin
  //
end;

initialization
begin
  RegisteredComponentList := TJSObject.Create(nil);
  RegisteredSelectionEditorList := TJSObject.Create(nil);
  RegisteredPropertyEditorList := TJSObject.Create(nil);
  RegisteredIgnoredPropertyList := TJSObject.Create(nil);
  RegisteredLibraryRequirementsEditorList := TJSObject.Create(nil);
  RegisteredComponentEditorList := TJSObject.Create(nil);
end;

end.

